import {getToken, setToken, removeToken, getApikey, setApikey, removeApikey, removeCurrentId} from '@/utils/auth'
import { getUserInfo,setUserInfo,removeUserInfo,getCurrentId,setCurrentId,removeAccount,removeSubList } from '@/utils/auth'
import {login,logout,signup,forgotPassword,resetPassword,getTimezoneList,updateProfile} from '@/api/user'
import { Message } from 'element-ui'
import {getProfileList, getUser} from "@/api/account";


const state={
    token:getToken(),
    apiKey:getApikey(),
    email:'',
    user:getUserInfo() ? JSON.parse(getUserInfo()) : {},
    profile:localStorage.getItem('pubrio-profile') ? JSON.parse(localStorage.getItem('pubrio-profile')):[],
    currentId:getCurrentId() ? JSON.parse(getCurrentId()) : {},
    timezoneList:localStorage.getItem('timezoneList') ? JSON.parse(localStorage.getItem('timezoneList')) : [],
    language: '',
    isSigned:true,
}
const mutations={
    setToken(state, {token,email}){
        state.token = token
        state.email = email
        if (state.isSigned) {
            setToken(token)
        }
    },
    setApikey(state,apiKey){
        state.apiKey = apiKey
        setApikey(apiKey)
    },
    setUserInfo(state,data){
        state.user = data
        setUserInfo(JSON.stringify(data))
    },
    setCurrentId(state,id){
        state.currentId = id
        setCurrentId(JSON.stringify(id))
    },
    removeUserInfo(state){
        removeToken()
        removeApikey()
        removeAccount()
        removeSubList()
        removeCurrentId()

    },
    setProfile(state, profile) {
        localStorage.setItem('pubrio-profile',JSON.stringify(profile))
    },
    setTimezoneList(state,data){
        localStorage.setItem('timezoneList',JSON.stringify(data))
    },
    setLanguage(state,data) {
        state.language = data;
    },
    setIsSigned(state,data) {
        state.isSigned = data;
    }
}
const actions={
   async login(context,data){
       const result = await login(data)
       let token = result.data.login.access_token
       let apiKey = result.data.login.user.api_key
       let email = result.data.login.user.email
       context.commit('setToken',{token,email})
       context.commit('setApikey',apiKey)
       const user = await getUser()
       context.commit('setUserInfo',user.data)
   },
    async getUserInfo(context){
        const profile = await getProfileList()
        context.commit('setProfile',profile.data)
        context.commit('setCurrentId',profile.data[0])
    },
   async getTimezoneList(context){
        const result = await getTimezoneList()
        context.commit('setTimezoneList',result.data)
        return result.data
    },
    async signup(context,data){
        const result = await signup(data)
        let token = result.data.signup.access_token
        let apiKey = result.data.signup.user.api_key
        let email = result.data.signup.user.email
        context.commit('setToken',token)
        context.commit('setApikey',apiKey)
        return email
    },
    async forgotPassword(context,data){
        const result = await forgotPassword({"email":data})
        Message.success(result.data.forgot_password.message)
    },
    async resetPassword(context,data){
        const result = await resetPassword(data)
        Message.success(result.data.reset_password.message)
    },
    async logout(context){
        const result = await logout({"email":state.email})
        context.commit('removeUserInfo')
    },
    async updateProfile(context,data){
        await updateProfile(data)
    }

}

export default{
    namespaced: true,
    state,
    mutations,
    actions
}
