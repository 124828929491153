import request from '@/utils/request';



export function login(data){
    return request({
        method: 'post',
        url: '/authentication/login',
        data
    })
}
export function logout(data) {
    return request({
        method: 'post',
        url: '/authentication/logout',
        data
    })
}
export function signup(data) {
    return request({
        method: 'post',
        url: '/authentication/signup',
        data
    })
}
export function forgotPassword(data) {
    return request({
        method: 'post',
        url: '/authentication/forgot_password',
        data
    })
}
export function resetPassword(data) {
    return request({
        method: 'post',
        url: '/authentication/reset_password',
        data
    })
}
export function getTimezoneList(data) {
    return request({
        method: 'get',
        url: '/timezones',
    })
}
export function updateProfile(data) {
    return request({
        method: 'post',
        url: '/profile/update',
        data
    })

}