export default {
    //login
    loading: '加載中...',
    SignUp: '註冊',
    LoginSuccess:'登錄成功',
    LoginOut:'退出成功',
    email: '郵箱',
    password: '密碼',
    logIn: '登錄',
    WelcomeBack: '歡迎回來',
    PleaseEnterYourEmailAndPassword: '請輸入您的郵箱和密碼',
    CharactersRequired: '不少於8個字符',
    keepMeSignedIn: '保持登錄',
    forgetPassword: '忘記密碼?',
    dontHaveAnAccount: '沒有賬號?',
    signUpNow: '立即註冊',
    AllRightsReserved: '版權所有',
    privacyPolicy:'隱私',
    and:'和',
    termsOfService:'條款',
    Name: '名稱',
    viewAll: '查看全部',
    fold: '收起',
//signup
    company:'公司',
    companyName:'公司名稱',
    signUp:'註冊',
    alreadyHaveAnAccount:'已有賬號',
    getStarted:'開始吧！用我們的平台為您的業務增添力量',
    signUpSuccess:'註冊成功',


//forget
    noWorries:'不用擔心！輸入您註冊的電子郵件地址，我們將向您發送重置說明。',

//reset
    reset:'重置密碼',
    forgotPassword:'忘記密碼',
    RequestPasswordReset:'請求密碼重置',
    backToLogin:'返回登錄',
    verifyPassword: '驗證密碼',
    EnterANewPasswordFor: '輸入新密碼',
    ResetPassword: '重置密碼',
    newPassword: '新密碼',

//top-nav
    Home: '首頁',
    Search: '搜索',
    Prospects: '客戶',
    filterYourSearchToFindHighlySpecific:'過濾搜索以找到高度特定的目標潛在客戶群',
    groupsOfTargetedLeads:'',
    filterYourSearchToFindPreciselyTargeted:'過濾搜索以找到精確定位的公司群',
    listsOfCompanies:'',
    transformAnonymousWebsiteTrafficInto:'將匿名網站流量轉化為實時識別的B2B',
    b2bLeadsWithRealtimeIdentification:'潛在客戶',
    discoverAndConnectWithPotentialBuyers:'積極發現並與潛在買家建立聯繫，尋求您',
    activelySeekingYourSolutionToday:'今天的解決方案',
    Intent_Signals: '趨勢信號',
    Website_Intent: '網站趨勢',
    Market_Intent: '市場趨勢',
    Sequence: '序列',
    Tools: '工具',
    technologyLookup:'技術查詢',
    domainLookup:'域名查詢',
    emailVerification:'郵箱認證',
    upgrade: '升級',
    helpCenter: '幫助中心',
    contactUs: '聯系我們',
    hello: '你好',
    clickToChange: '點擊更改',
    yourProfile: '個人資料',
    currentUsage: '使用情況',
    upgradePlan: '升級計劃',
    integrations: '集成',
    featureRequest: '特色請求',
    change: '更改',
    en:'英文',
    cn:'中文（簡）',
    tw:'中文（繁）',
    logout: '退出',
    switchLanguage: '切換語言成功',

//not available
    ComingSoon:'即將推出！',
    TheFeatureIscurrentlyUnder:'該功能目前正在開發中',
    development:'',
    weAppreciateYourPatienceAsWeWorkToImprove:'我們感謝您的耐心等待，我們將努力改善',
    yourExperience:'您的體驗',
    inTheMeantimeIfYouHaveAnyQuestionsOrNeed:'同時，如果您有任何問題或需要幫助，',
    assistancePleaseContactOurSupportTeam:'請聯系我們的支持團隊',
    contactSupport: '聯系支持',
    ok: '確定',
    thankYouForBeingAValuedUserOfOurPlatform: '感謝您成為我們平台的重要用戶！',


//search-company
    people:'人',
    companies:'公司',
    clearAll: '清除全部',
    searchProspects: '搜索客戶、關鍵詞...',
 //actions
    isFoundIn: '在',
    isNotFoundWithinAnyLists: '不在任何列表中',
    l: '個列表中找到',
    remove: '移除',
    saveToLists: '保存到列表',
 //delete dialog
    areYouSureYouWantToDeleteThisRecord: '您確定要刪除此記錄嗎？',
    proceedingWithDeletionWillResultInPermanentRemovalOfTheList: '繼續刪除將導致永久刪除列表',
    delete: '删除',
    byCancelingYouEnsureThatNoListsWillBeRemoved: '通過取消，您可以確保不會刪除任何列表',
    DeleteFailed: '刪除失敗',
//export dialog
    holdOnWereGeneratingYourExport: "請稍等，我們正在生成您的導出",
        UseThisButtonOnlyIfYouWishToCancelYourAction: '僅在您希望取消操作時使用此按鈕',
//save search pop
    saveSearch: '保存搜索',
    newSaveSearch: '新建保存搜索',
    UpdateSavedSearch: '更新保存搜索',
    enterNewSearchName: '輸入新搜索名稱',
    visibility: '可見性',
    manageWhoCanSeeYourSavedSearch: '管理誰可以看到您的保存搜索',
    private: '私有',
    onlyYouCanSeeYourSavedSearch: '只有您可以看到您的保存搜索',
    everyoneCanSeeYourSavedSearch: '每個人都可以看到您的保存搜索',
    pinSavedSearch: '將保存搜索固定在頂部',
    findSavedSearch: '查找保存搜索',
    enterSavedSearchName: '輸入保存搜索名稱',
    duplicate: '重複',
    update: '更新',
    noSavedSearches: '沒有保存的搜索',







  //companyLink\contactBtn\functionBtn
    more: '更多',
    verified: '已驗證',
    GetEmailSuccessfully: '獲取郵箱成功',
    CopySuccessfully: '複製成功',
    recorded: '已記錄',
    implement: '執行',
    sequence: '序列',
    of: '  總條數：',
    toAssistYouEffectivelyWeRequireFurtherDetailsAbout:'為了有效地幫助您，我們需要有關您的搜索偏好的更多詳細信息。請利用左側面板中的篩選選項來指定您所需的條件，以便我們能夠優化搜索結果並為您提供滿足您要求的目標群體資料。',
//filter
    all: '全部',
    my: '我的',
    team: '團隊',
    lists: '列表',
    employees: '員工',
    technologies: '技術',
    socialmedia: '社交媒體',
    enterSocialMedia: '請輸入社交媒體',
    noResultsFound: '未找到結果',
    enterCompanies: '請輸入公司',
    searchCompanies: '搜索公司',
    filters: '過濾器',
    accountHqLocation: '總部位置',
    CityStateCountryZIP:'城市/州/國家/郵編',
    enterLocations: '请輸入位置',
    revenue:'收入',
    chooseARangeOfRevenue:'選擇收入範圍',
    ofRevenueIsUnknown:'收入未知',
    technology:'技術',
    category:'類別',
    categories:'類別',
    enterCategories:'請輸入類別',
    enterTechnologies:'請輸入技術',
    employee:'員工',
    predefinedRange: '預定義範圍',
    ofEmployeesIsUnknown:'員工數量未知',
    enterNames:'請輸入名稱',
    jobTitle:'職位',
    searchForAJobTitle: '搜索職位',
    enterIndustries: '请輸入行業',
    founded:'成立時間',
    chooseARangeOfFoundedTime:'選擇時間範圍',
    from:'從',
    to:'到',
    specialties:'特色',
    enterSpecialties: '請輸入特色',
    location:'位置',
    total: '總數',
    department: '部門',
    EnterDepartments: '請輸入部門',
    JobTitle: '職位',
    EnterJobTitles: '請輸入職位',
    ManagementLevel: '管理層級',
    EnterManagementLevels: '請輸入管理層級',
    AccountHQ: '地址',
    ContactHQ: '總部聯系方地址',
    departmentFunction: '部門職能',
    EnterJobFunctions: '請輸入部門職能',
    enterNamesAndPressTheEnterLKey: '請輸入名稱並按Enter鍵',
    EnterJobTitlesAndPressTheEnterLKey: '請輸入職位並按Enter鍵',
    pleaseSelectRange: '請選擇範圍',




//table
    selectAllCompanies: '選擇所有公司',
    selected: '已選擇',
    export: '導出',
    industry: '行業',
    keywords: '關鍵詞',
    companyLocation: '公司位置',
    action: '操作',
    PleaseEnterTheSelectedQuantity: '請輸入選定的數量',
    contact: '聯系方式',
    Title: '職位',
    selectThisPage: '選擇此頁',
    clearSelection: '清除選擇',
    selectNumberOfCompanies: '選擇公司數量',
    maxCompanies: '最大公司數量',
    youCanOnlySelectAMaximumOf25Companies: '您最多只能選擇25家公司。',
    UpgradeYourPlanNow: '立即升級您的計劃！',
    viewPlans: '查看計劃',
    applySelection: '應用選擇',
    selectNumberOfProspects: '選擇客戶數量',
    maxProspects: '最大客戶數量',
    youCanOnlySelectAMaximumOf25Prospects: '您最多只能選擇25個客戶。',
    PleaseSelectTheDataToBeExported: '請選擇要導出的數據',
    overview: '概述',
    genericContacts: '聯系方式',
    size: '規模',
    contactLocation: '聯系地址',

//account
    addCreditCard:'添加信用卡',
    zip: '郵編',
    country: '國家',
    state: '州',
    city: '城市',
    address: '地址',
    billingInformation: '帳單信息',
    cvc: 'CVC',
    year: '年',
    month: '月',
    expirationDate: '到期日',
    creditCardNumber: '信用卡號碼:',
    cardInformation: '卡信息',
    title: '標題',
    fullName: '全名',
    yourDetail: '您的詳細信息',
    creditsToUse: '待使用積分',
    youHave: '您有',
    download: '下載',
    buyMoreCredits: '購買更多積分',
    description: '描述',
    topUpCredits: '充值積分',
    cancel: '取消',
    date: '日期',
    invoiceHistory: '發票歷史',
    confirmNewPass: '確認新密碼',
    creditsUsed: '已使用積分',
    newPass: '新密碼',
    youHaveNotEnteredAnyCreditCardInformationYet: '您尚未輸入任何信用卡信息',
    monthlyCredit: '每月積分',
    oldPass: '舊密碼',
    cardNumber: '卡號',
    changePassword: '更改密碼',
    creditUsage: '積分使用情況',
    typeTheNewTitle: '輸入新標題',
    save: '保存',
    monthlyTotal: '每月總計',
    addMoreCredits: '添加更多積分',
    addonCredits: '附加積分',
    creditsMo: '积分/月',
    apiIntegrations: 'API集成',
    creditsInYourPlan: '您計劃中的積分',
    settings: '設置',
    phone: '電話',
    '1User': '1個用戶',
    users: '用戶',
    billing: '賬單',
    monthlyQueryLimit: '每月查詢限額',
    price: '價格',
    whatsIncluded: '包括什麼',
    givenName: '名',
    profile: '個人和團隊',
    purchasePlan: '購買計劃',
    accountOverview: '賬戶概述',
    familyName: '姓',
    dailyQueryLimit: '每日查詢限額',
    rateLimit: '限制',
    youAreOnA:'您正在',
    PlanAndYourCreditsWillRefreshOn: '計劃中，您的積分將在',
    过期:'更新',
    searchSettings: '搜索設置',
    plan: '計劃',
    accountInfo: '賬戶信息',
    creditCardInformation: '信用卡信息',
    apiUsage: 'API使用情况',
    mo:'/月',
    perUserPerMonth:'每用戶每月',
    billedAnnually:'按年計費',
    dailyQuery:'每日查詢',
    monthlyQuery:'每月查詢',
    currentPlan:'當前計劃',
    everythingInPlus:'Everything In Plus',
    mostPopular:'最受歡迎',
    topUpToIncreaseYourCredits:'增加您的積分',
    addCredits:'購買積分',
    allPlansInclude:'所有計劃均包',
    Amount:'金額',
    credits:'積分',
    purchaseSuccess:'購買成功，您的總積分為',
    purchaseFailed:'購買失敗',
    Loading:'加載中',
    Selected:'已選擇',
    SelectPlan:'選擇計劃',
    upgradeSuccess:'計劃切換成功',
    upgradeFailed:'計劃切換失敗',
    inviteSentSuccessfully:'邀請發送成功',
    DeleteSuccessfully:'刪除成功',
    AccountupdatedSuccessfully:'賬戶更新成功',
    AccountUpdateFailed:'賬戶更新失敗',
    PasswordUpdatedSuccessfully:'密碼更新成功',
    PasswordUpdateFailed:'密碼更新失敗',
    Users:'用戶',
    newUser:'新用戶',
    deleteUser:'刪除用戶',
    admin:'管理員',
    inviteYourTeam:'邀請您的團隊',
    empowerEveryoneToGetMoreDealsDonefaster:'授權每個人更快地完成更多交易',
    addMultipleEmailAddressesSeparatedByCommas:'添加多個電子郵件地址，并用逗號分隔',
    sendInvites:'發送邀請',
    yourTeams:'您的團隊',
    AreYouSureYouWantToDeleteThisTeamMember:'您確定要刪除此團隊成員嗎？',
    teamDetails:'團隊詳情',
    teamName:'團隊名稱',
    changeTeam:'切換團隊',
    clickToChangeTeam:'點擊更改團隊',
    changeTeamSuccessfully:'切換團隊成功',
    status:'狀態',
    Action:'操作',
    pending:'待定',
    current:'當前',
    AreYouSureYouWantToDeleteThisCreditCard:'您確定要刪除此信用卡嗎？',
    PasswordCannotBeEmpty:'密碼不能為空',
    nickname:'昵稱',
    FirstName:'名',
    LastName:'姓',
    timezone:'時區',
    contactNo:'聯系電話',
    controlYourTeamMembersAndTheirAccountAccessRightHere:'在此處管理您的團隊成員及其賬戶訪問權限',
    pleaseEnterYourCurrentPasswordToChangeYourPassword:'請輸入當前密碼以更改密碼',
    updatePassword:'更新密碼',
    oldPassword:'舊密碼',
    confirmNewPassword:'確認新密碼',
    InviteYourTeam:'邀請您的團隊',
    empowerEveryoneToGetMoreDealsDoneFaster:'授權每個人更快地完成更多交易',
    enterEmailAddressesMultipleCommaseparated:'輸入電子郵件地址（多個，用逗號分隔）',
    invoice:'發票',
    accountAdmin:'賬戶管理員',
    setAsDefault:'設為默認',
    bankName:'銀行名稱',
    expiry:'到期',
    viewAllYourPreviousInvoicesHere:'在此處查看您以前的所有發票',
    BillingHistory:'賬單歷史記錄',
    updateYourCreditCardInformation:'更新您的信用卡信息',
    ManageCreditCard:'管理信用卡',
    CardNumber:'卡號',
    billingAddress:'賬單地址',
    yourCreditsWillRefreshOn:'您的積分將在',
    perMonth:'每月',
    features:'特征功能',
    credits_mo:'積分/月',
    _mo:'/月',
    seeOtherPlan:'查看其他計劃',
    subscriptionTiers:'訂閱區',
    experiencePricingThatsEasyToUnderstandAndAdaptable:"體驗易於理解且易於適應的價格",
    monthlyBilling:'月度計費',
    annualBilling:'年度計費',
    forPlanCustomizationContactOurSalesTeam:'有關計劃定制的更多信息，請聯系我們的銷售團隊',
    billedAnnually_user:'按年計費/用戶',
    seePlanComparison:'查看計劃比較',
    topUp:'充值',
    changeTeamNameFailed:'更改團隊名稱失敗',
    purchaseCreditsAtTheRateOf100PerCreditCustomizeyourCreditBundleBasedOnYourNeeds:'按1美元/積分購買積分，請根據您的需求定制積分包',
    totalPrice:'總價',
    credit:'積分',
    RemoveCreditCard: '刪除信用卡',
    enterATitleForYourCard: '為您的卡輸入標題',
    enterYourCardNumber: '輸入您的卡號',
    digit: '3位數字',
    provideYourCompleteAddress: '提供您的完整地址',
    selectYourCountry: '選擇您的國家',



// Intent
    intentOverview: "概覽",
    intentAnalytic: "分析",
    intentFilter: "篩選",
    searchCompany:'搜索公司、網站、領英...',
    savedCompanyWebsite: '已保存公司的網站',
    companiesFound: '家公司',
    companyFound: '家公司',
    filterList: {
      technologies: '技術',
      time: '時間',
      locations: '賬號位置',
      verticals: '行業',
      pageview_greater_than: '頁面預覽',
      session_greater_than: '會話',
      advertisements: '廣告',
      company_groups: '列表',
    },
    employeeFilterList: {
      industry: '行業',
      technology: '技術',
      jobTitle: '職位',
      company: '公司',
      list: '清單',
      name: '名字',
      size: '規模',
      location: '位置',
      jobFunction: '工作職能'
    },
    selectLists:'請選擇列表',
    chooseARangeOfTime: '時間段選擇',
    greaterThan: '大於',
    selectAdvertisement: '選擇廣告',
    // intentIndustry: '行業',
    tabActivity: '活動',
    tabOverview: '概覽',
    tabEmployees: '員工',
    tabContacts: '通用聯繫人',
    tabTechnologies: '技術',
    intentStatistic: '統計',
    intentCampaign: '活動',
    newVisitors: '新訪客',
    recurringVisitors: '常客',
    mediocreLead: '普通的潛在客戶',
    sessionsTracked: '個跟踪會話',
    sessionTracked: '個跟踪會話',
    lastSeenOn: '最後查看',
    firstSeenOn: '首次查看',
    viewTrackedWebSession: '查看跟踪的網絡會話',
    webSession: '網絡會話',
    client: '客戶端',
    timeSpent: '花費時間',
    pageviews: '瀏覽量',
    pageview: '瀏覽量',
    referrer: '來源',
    sessions: '會話',
    session: '會話',
    visited: '訪問',
    AM: '早晨',
    PM: '下午',
    companyDetails: '公司詳情',
    overviewList: {
      location: '位置',
      industry: '行業',
      founded: '創建時間',
      ranking: '排名',
      size: '規模',
      revenue: '收入',
      keywords: '關鍵詞',
    },
    advancedSearch: '高級搜索',
    jobFunction: '工作職能',
    employeeLocation: '位置',
    concatLocation: '聯繫位置',
    contactEmail: '郵件',
    categoryAndTechnology: '類別和技術',
    firstDetected: '首次查看',
    lastDetected: '最後查看',
    saved: '已保存',
    addToContactList: '添加到連絡人列表',
    noCampaignIdentified: '暫時沒有活動',
    copyEmail: '複製郵箱',
    willBeSavedToTheFollowingSelectedLists: '將會被保存到下列選中的列表:',
    addToLists: '添加到列表',
    noDataToDisplay:'暫無更多數據',
    intentFounded: '創建時間',
    intentIndustry: '行業',
    intentRevenue: '收入',
    intentSize: '規模',
    applyFilters: '篩選',
    youCanEditTheseFilters: '您可以隨時編輯這些選項以篩選結果',

    // analytic: '統計分析',
    // allLeads: '所有線索',
    // campaign: '活動',
    // medium: '媒體',
    // source: '來源',
    // term: '项目',
    // activities: '活動',
    // genericContacts: '通用聯繫人',
    // ranking: '排名',
    // size: '規模',
    // social: '聯繫方式',
    // website: '網站',
    // campaigns: '活動',
    installB2BDirectLeadFinder:'在您的網站上安裝 B2B Direct Lead Finder',
    addSite: '添加站點',
    areYouSureYouWantToRemoveThisWebsite: '您確定要移除這個網站嗎？',
    Cancel: '取消',
    installB2BLeadFinder:'安裝 B2B Lead Finder',
    pleaseTellUsABitAboutTheWebsiteYoudLikeToAdd: "請告訴我們您要添加的網站的一些信息",
    siteUrl: '站點網址',
    copyToClipboard: '複製到剪貼板',
    pleaseSelectAContactFirst: '請先選擇一個聯繫人',
    pasteCodeBelowIntoTheHead: '將下面的代碼粘貼到您希望跟踪訪問者的每個頁面的',
    ofEveryPageYouWishToTrackVisitorOn: '中',
    forWordpressWeRecommendInstalling: '對於 WordPress，我們建議安裝來自 Stefano Lissa 的',
    pluginFromStefanoLissa: '插件。',
    afterInstallingAndActivating: '安裝並激活插件後，導航到 WordPress 儀表板中的',
    inYourWordPressDashboard: '。',
    youWillSeeANumberOfTabs: '您會在插件界面中看到許多選項卡， 確保您位於默認的“頁眉和頁腳”選項卡中，然後復制並粘貼以下代碼片段:',
    settingHeaderAndFooter: '設置 → 頁眉和頁腳',
    dontForgetToClearYourCache: "不要忘記清除緩存",
    ifYouAreUsingAnyCaChing: '如果你正在使用像',
    orSimilar:"之類的插件, 確保在安裝代碼段後已經清除緩存!",
    GTMshouldAlreadyBeInstalled: '在按照這些說明操作之前，您的網站上應該已經安裝了 Google Tag Manager',
    ifYouDontHaveGTM: "如果您還沒有安裝 Google Tag Manager，您可以在",
    GTMsupportArticleAboutInstall: 'Google Tag Manager 支持的文章中了解如何安裝它',
    signInToGTMAndClick: '登錄 Google Tag Manager，然後點擊添加新標籤按鈕。然後',
    clickWhereItSays: '點擊',
    chooseATagTypeToBeginSetup: ' "Choose a tag type to begin setup" ',
    andSelect: '並從列表中選擇 ',
    fromTheList: '，然後將以下代碼複製並粘貼到文本字段中。',
    nextClick: '然後點擊 ',
    giveTheTagAName: '，為標籤命名，然後單擊“保存”按鈕。',
    dontForgetToPublishYourChange: "不要忘記發布您的更改",
    afterCreateTheCustom: '創建 "Custom HTML" 標記後，不要忘記',
    yourChanges: '你的更改!',
    submitAndPublish: '提交並發布',
    copySuccessfully: '複製成功',
    addToCompanyList: '添加到公司列表',
    // addToList: '添加到列表',
    // enterOrCreateList: '輸入或創建列表',
    installCode: '安裝代碼',
    wordpress: 'Wordpress',
    googleTagManager: 'Google Tag Manager',
    noVisitorsYet: '暫無瀏覽',
    onceTheInsightsRollIn: "一旦深入瞭解，您將解鎖有關訪客等的寶貴資訊。",
    noTechAreFound: '暫無技術內容',
    updateSuccessfully: '更新成功',
    enterJobFunction: '請輸入工作職能',
    pinSuccessfully: '置頂成功',
    cancelPinSuccessfully: '取消置頂成功',
    adsName: '廣告名稱',
// analytic
    showNumber: '展示條數',
    Website: '網站',
    b2bTraffic: 'B2B 貿易',
    b2bReturningVsNew: 'B2B 常客 V.S. 新用戶',
    visitors: '訪客',
    totalView: '总浏览量',
    b2bPageViews: 'B2B 浏览量',
    totalSessions: '全部会话',
    operatingSystem: '操作系統',
    browser: '瀏覽器',
    device: '設備',
    companySize: '公司規模',
    topIndustry: '熱門行業',
    audience: '觀眾',
    topCountries: '熱門國家',
    topReferrals: '熱門推薦',
    new: '新用戶',
    returning: '老用戶',
    countriesStatistics: '國家統計',
    topCompaigns: '熱門活動',
    topPagesByVisits: '流覽量分析',
    topPagesByEngagement: '參與度分析',
    Referrals: '推薦',
    Pages: '頁面',
    avgTime: "平均時間",
    totalCompanies: '全部公司',
    b2bPageViews: 'B2B 頁面瀏覽',
    TotalSessions: '全部會話',
    viewCompanyList: '查看全部公司',
// mycontact
    prospects: '前景',
    list: '列表',
    view: '查看',
    // delete: '刪除',
    add: '添加',
    newList: '新建列表',
    savedLists: '已保存列表',
    searchList: '搜索列表名稱',
    owner: '所有者',
    records: '記錄',
    actions: '操作',
    lastModified: '最後修改',
    name: '姓名',
    enterAListName: '請輸入列表名字',
    pleaseSelectAListFirst: '請先選擇一個列表項',
    saveSuccessfully: '保存成功',
    pleaseEnterAListName: '請輸入列表名稱',
    editList: '編輯列表',
    fromThisList: '从列表中移除,您確定要繼續嗎',
    areYouSureYouWantToChange: '您確定要將列表類型更改為',
    // yes: '確定',
    noListCreated: '暫無列表',
    enhanceTheOrganization: '加强您在目標市場內連絡人和帳戶的組織。 創建清單後，它們將顯示在此頁面上。',
    createSuccessfully: '創建成功',
    removeSuccessfully: '移除成功',
    createNewList: '創建新列表',
    groupTeam: '團隊',
    groupPersonal: '個人',
    edit: '編輯',
    areYouSureYouWantToDeleteTheList: '你確定要删除這個列表嗎?',
    proceedingWithDeletion: '繼續删除將導致永久删除該列表。',
    byCanceling: '若您取消，不會有任何清單被删除',
    byCancelingYouEnsureThatNo: '通過取消，您可以確保沒有',
    willBeRemoved: '會被移除',
    groupPeople: '人員',
    groupCompany: '公司',
    myList: '我的列表',
    teamList: "團隊列表",
    resetAssured: "請放心，您可以根據需要靈活地在不同類型之間切換。",
    wontBeChanged: "通過取消，您可以確保此清單類型不會更改",
    priority: '優先級',
    proceedingWithDeletionWillResultInPeopleDelete: '繼續删除將導致永久删除人員',
    proceedingWithDeletionWillResultInCompanyDelete: '繼續删除將導致永久删除公司',
    areYouSureYouWantToRemoveCompanyFromList: '您確定要將已選中公司從下列清單中删除嗎',
    areYouSureYouWantToRemovePeopleFromList: '您確定要將已選中人員從下列清單中删除嗎',
    groupExisted: '群組已經存在',
    sorryTheListIsCurrrntlyOpen: '抱歉，列表當前處於打開狀態。 你確定要删除它嗎？',


// market-intent
    comingSoon: '即將開放',
    weAreCurrentlyWorkingOnThisFeature: '我們目前正在開發此功能，並將很快推出',
    stayConnectedWithUs: '通過我們的社交媒體渠道與我們保持聯繫以接收更新',
    selectAWebsite: '請選擇一個網站',


    // show: '展示',
    // peopleLists: '人列表',
    // companyLists: '公司列表',
    // contactLists: '聯繫人列表',
    // searchList: '搜索列表',
    // name: '姓名',

    // my: '我的',
    // team: '團隊',
    // all: '全部',
    // newList: '新建列表',
    // confirm: '確認',
    // yes: '確認',

    // delete: '刪除',
    // edit: '編輯',
    // enterANewListName: '请输入新列表名称',
}