<template>
  <div id="right" >
    <el-button class="btn-nav-upgrade" @click="handleUpgrade">
      {{ $t('upgrade') }}
    </el-button>
    <el-dropdown class="question" trigger="click">
      <span class="btn-nav-icon">
         <i class="el-icon-help"></i>
      </span>
      <el-dropdown-menu slot="dropdown"
                        :append-to-body="false"
                        class="questionC" :visible-arrow=false>
        <el-dropdown-item>
          <a href="https://www.pubrio.com/faqs" target="_blank" class="rlink">
            <svg-icon icon-class="Help"/>
            <span>{{ $t('helpCenter') }}</span>
          </a>
        </el-dropdown-item>
        <el-dropdown-item>
          <a href="https://www.pubrio.com/contact-us" target="_blank">
            <svg-icon icon-class="about us"/>
            <span>{{ $t('contactUs') }}</span>
          </a>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <div class="setting">
      <router-link :to="{name:'account'}" class="btn-nav-icon">
        <i class="el-icon-setting"></i>
      </router-link>
    </div>

    <div id="user" class="user" >
      <div class="avatar" @click="handleShow1">
          <el-button class="btn-nav-user">{{ KL }}</el-button>
        </div>
      <div class="menu" v-if="isShow1" >
        <div class="userName">
          {{ $t('hello')}},  {{name | bigFirstLetter}}
        </div>
        <div class="currentId">
            <span class="organ" @click="handleShow2">
                  <div>{{organization |bigFirstLetter}}</div>
                  <div class="changeOrgan">
                    {{ $t('change') }}
                    <i class="el-icon-arrow-down"></i>
                  </div>
              </span>
            <div class="menu" v-if="isShow2">
              <div class="dropItem"
                   v-for="(item,index) in profile"
                   :key="index" >
                <a @click="chooseProfile(item)" :class="item.name === organization ? 'active':''">
<!--                  <i class="el-icon-arrow-right"></i>-->
                  {{ item.name | bigFirstLetter}}
                </a>
              </div>
            </div>
        </div>
        <div class="dropItem">
          <router-link :to="{name:'account'}">
            <svg-icon icon-class="profile"/>
            {{ $t('yourProfile') }}
          </router-link>
        </div>
<!--        <div class="dropItem">
          <router-link :to="{name:'teams'}">
            <svg-icon icon-class="Team"/>
            {{ $t('yourTeams') }}
          </router-link>
        </div>-->
        <div class="dropItem">
          <router-link :to="{name:'currentUsage'}">
            <svg-icon icon-class="Frame 6543"/>
            {{ $t('currentUsage') }}
          </router-link>
        </div>
        <div class="dropItem">
          <router-link :to="{name:'upgrade'}">
            <svg-icon iconClass="rocket"></svg-icon>
            {{ $t('upgradePlan') }}
          </router-link>
        </div>
        <div class="dropItem">
          <router-link :to="{name:'billing'}">
            <svg-icon iconClass="Frame 6542" ></svg-icon>
            {{ $t('billing') }}
          </router-link>
        </div>
        <div class="dropItem feature">
          <a href="https://www.pubrio.com/contact-us">
            <svg-icon iconClass="Request" ></svg-icon>
            {{ $t('featureRequest') }}
          </a>
        </div>
        <div class="logout" >
          <el-button size="medium" class="btn-sub" @click="logout">
            {{ $t('logout') }}
          </el-button>
        </div>
      </div>
    </div>

    <div class="lang">
      <el-dropdown trigger="click" @command="changeLanguage">
            <span class="btn-nav-lang">
              {{$t(currentEn)}}
              <span></span>
            </span>
        <el-dropdown-menu slot="dropdown" :visible-arrow=false :append-to-body="false">
          <el-dropdown-item command="en" :disabled="'zh'=== $i18n.locale "><span>English (EN)</span></el-dropdown-item>
          <el-dropdown-item command="cn" :disabled="'cn'=== $i18n.locale "><span>中文（简）</span></el-dropdown-item>
          <el-dropdown-item command="tw" :disabled="'tw'=== $i18n.locale "><span>中文（繁）</span></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import { mapMutations } from 'vuex'
export default {
  name: "top-right",
  data() {
    return {
      currentEn:this.$i18n.locale,
      isShow1:false,
      isShow2:false,
    };
  },
  computed:{
    currentId(){
      return this.$store.state.user.currentId.profile_id
    },
    organization(){
      if(this.$store.state.user.currentId){
        return this.$store.state.user.currentId.name
      }
    },
    name(){
      return this.$store.state.user.user.nickname
    },
    KL(){
      if(this.name){
        let arr = this.name.split(' ')
        if(arr.length === 1){
          return this.name.charAt(0).toUpperCase()
        } else {
          return this.name.split(' ')[0].charAt(0).toUpperCase()+this.name.split(' ')[1].charAt(0).toUpperCase()
        }
      }
    },
    profile(){
      return this.$store.state.user.profile
    }
  },
  watch:{
    $route(to) {
      if(to.path.indexOf('/setting') > -1){
        this.isShow1 = false
      }
    },
  },
  filters:{
    bigFirstLetter(str) {
      if(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    }
  },
  methods: {
    ...mapMutations('user', ['setLanguage']),
    async chooseProfile(item){
      this.isShow2 = false
      await this.$store.commit('user/setCurrentId',item)
    },
    handleUpgrade(){
      this.$router.push({name:'upgrade'})
    },
    async logout(){
      let companySelected = this.$store.state.company.selected
      localStorage.setItem('companySelected',JSON.stringify(companySelected))
      let peopleSelected = this.$store.state.people.selected
      localStorage.setItem('peopleSelected',JSON.stringify(peopleSelected))

      await this.$store.dispatch('user/logout')
      await this.$router.push('/login?returnUrl=' + encodeURIComponent(this.$route.fullPath))
      let mes = this.$t('LoginOut')
      this.$message.success(mes)
    },
    /*-------------------切换语言---------------------------*/
    changeLanguage(lang) {
      this.currentEn= lang
      Cookie.set('language', lang) // 切换多语言
      this.$i18n.locale = lang // 设置给本地的i18n插件
      let mes = this.$t('switchLanguage')
      this.setLanguage(lang);
      this.$message.success(mes)
    },
    handleShow1(){
      this.isShow1 = !this.isShow1
    },
    handleShow2(){
      this.isShow2 = !this.isShow2
    },
    hideDropdown(e){
      let user = document.getElementById('user')
      if(user){
        if(!user.contains(e.target)){
          this.isShow1 = false;
          this.isShow2 = false;
        }
      }
      let currentId = document.getElementsByClassName('currentId')[0]
      if(currentId){
        if(!currentId.contains(e.target)){
          this.isShow2 = false;
        }
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.hideDropdown);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideDropdown);
  },
}
</script>

<style scoped lang="scss">
.btn-nav-upgrade{

}
@media (max-width: 991px) {
  .btn-nav-upgrade{
    display:none!important;
  }
}
@media (max-width: 767px) {
  #right{
    flex:1!important;
    justify-content: end;
    align-items: center;
    gap:10px!important;
    padding:0!important;
  }
}
#right{
  gap:20px;
  display:flex;
  align-items: center;
.el-button{
  flex:2;
  font-size: 14px;
}
  .question{
    ::v-deep .el-dropdown-menu{
      width: 232px;
      margin-right: -100px;
      padding-bottom: 12px;
      .el-dropdown-menu__item {
        width: 100%;
        padding:0 16px!important;
        a{
          display: flex;
          align-items: center;
          height: 44px;
          line-height: 40px;
          padding: 10px;
          font-weight: 600;
          font-size: 16px;
          color:$color-black;
          .svg-icon{
            margin-right: 16px;
            font-size: 20px;
          }
        }
        .rlink{
          border-bottom:0.4px solid #E1E2E6;
        }
      }
    }
  }
.setting{
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  color:#949fb1;
}
.setting:hover{
  color:#146ef6;
}

  #user {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.userName{
  padding:0 16px;
  color:$color-blue-5!important;
  font-weight: 700;
  }
  .menu{
    position: absolute;
    top:50px;
    right:-80px;
    width: 346px;
    padding:16px 0;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 #2636990F;
  }
.dropItem{
  a{
    display: block;
    height: 48px;
    line-height: 48px;
    margin:0 16px;
    padding:0 10px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.03em;
    border-bottom: 0.4px solid $color-gray-0;
    color:$color-black;
    .svg-icon{
      font-size: 20px;
      margin-right: 16px;
    }
  }
}
 .feature{
      a{
        border:0;
      }
    }
.dropItem:hover{
  background: $color-blue-1;
}
  .btn-sub{
    width: 314px;
    margin:26px auto 0;
  }
  .currentId{
    position: relative;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.03em;
    color:$color-black;
    padding:0 16px;
    .organ{
      height: 48px;
      display:flex;
      align-items: center;
      justify-content: space-between;
      div:first-child{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $color-black;
      }
      div:last-child{
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: $color-black;
      }
    }
      .menu{
        position: absolute;
        top:40px;
        right:16px;
        width: 314px;
        padding:0 16px;
        border-radius: 8px;
        .dropItem{
          font-weight: 600;
          line-height: 48px;
          color: $color-black;
          a{
            margin:0;
            padding-left:9px;
          }

        }
      }
  }
  .active{
    color:$color-blue-5!important;
  }
  .changeOrgan{
    margin-top: -12px;
    height:15px;
    line-height: 15px;
    font-size: 12px;
    color:#949fb1;
  }
}
  .lang{
    width: 90px;
    font-weight: 700;
    cursor: pointer;
    ::v-deep .el-dropdown-menu {
      margin-right: -30px;
      width: 155px !important;
      padding-bottom: 12px;
      border-radius: 8px;
      box-shadow: 0 4px 8px 0 #2636990F;
      .el-dropdown-menu__item {
        span{
          display: flex;
          align-items: center;
          height: 44px;
          line-height: 40px;
          padding:0 10px;
          font-weight: 600;
          font-size: 16px;
          color:$color-black;
          border-bottom:0.4px solid #E1E2E6; ;
        }
      }
    }
  }
  ::v-deep .el-dropdown-menu {
    .el-dropdown-menu__item {
      width: 100%;
      padding:0 16px;
    }
    .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
      background: $color-blue-1;
    }
  }
}

</style>