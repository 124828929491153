
import { getCompanyGroupList, getContactGroupList, getPeopleGroupList } from '@/api/contact';
import { PEOPLE_TYPE, COMPANY_TYPE,CONTACT_TYPE, CATEGORY_MY, CATEGORY_TEAM } from '@/views/mycontact/constants';

const state = {
    list: [],
	filterList: [],
	loading: false,
	type: PEOPLE_TYPE,
	isPersonal: null,
	selectList: [],
	myNum: 0,
	teamNum: 0,
	groupType: null,
	tempFilterList: [],
	intentFilterGroup: [],
	savedGroup: [],//保存临时勾选的group
};

const mutations = {
	setList(state, payload) {
		state.list = payload;
	},
	setFilterList(state, payload) {
		state.filterList = payload;
	},
	setLoading(state, payload) {
		state.loading = payload;
	},
	setPersonal(state, payload) {
		state.isPersonal = payload;
	},
	setType(state, payload) {
		state.type = payload;
	},
	setSelectList(state, payload) {
		state.selectList = payload;
	},
	setMyNum(state, payload) {
		state.myNum = payload;
	},
	setTeamNum(state, payload) {
		state.teamNum = payload;
	},
	setGroupType(state, payload) {
		state.groupType = payload;
	},
	setTempFilterList(state, payload) {
		state.tempFilterList = payload;
	},
	setIntentTempFilter(state, payload) {
		state.intentFilterGroup = payload;
	},
	setSavedGroup(state, payload) {
		state.savedGroup = payload;
	}
};

const actions = {
	async getList({ commit,state, rootState }, data) {
		commit('setLoading', true);
		const profileId = rootState.user.currentId.profile_id;
		const { type, isPersonal, list, groupRemove } = data; let result = null;
		commit('setType', type);
		commit('setPersonal', isPersonal);
		const temp = groupRemove ?
		 (state.groupType === 2 ? { companies: list.map((item) => item.domain_id) } : { peoples: list.map((item) => item.people_id) })
			: {}
		const params = {
			profile_id: profileId,
			is_personal: isPersonal,
			...temp,
		};
		switch(type) {
			case PEOPLE_TYPE: 
				result = await getPeopleGroupList(params);
				break;
			case COMPANY_TYPE: 
				result = await getCompanyGroupList(params);
				break;
			case CONTACT_TYPE: 
				result = await getContactGroupList(params);
				break;
		};
		commit('setList',result.data);
		commit('setFilterList', result.data);
		commit('setLoading', false);
		if (isPersonal === null) {
			const len = result.data.length;
			const myNum = result.data.filter((item) => item.is_personal === true)?.length;
			const teamNum = len - myNum;
			commit('setTeamNum',teamNum);
			commit('setMyNum', myNum);
		}
	},
	async sortListByPriority({ commit,state, rootState }, data) {
		commit('setLoading', true);
		const profileId = rootState.user.currentId.profile_id;
		const { type, sortBy } = data; let result = null;
		commit('setType', type);
		commit('setPersonal', null);
		const params = {
			profile_id: profileId,
			is_personal: null,
		};
		switch(type) {
			case PEOPLE_TYPE: 
				result = await getPeopleGroupList(params);
				break;
			case COMPANY_TYPE: 
				result = await getCompanyGroupList(params);
				break;
			case CONTACT_TYPE: 
				result = await getContactGroupList(params);
				break;
		};
		commit('setList',result.data);
		const myList = result.data.filter((item) => item.is_personal === true);
		const teamList = result.data.filter((item) => !item.is_personal);
		if(sortBy === CATEGORY_MY) {
			commit('setFilterList', [...myList, ...teamList]);
		} else if (sortBy === CATEGORY_TEAM) {
			commit('setFilterList', [...teamList, ...myList]);
		}
		commit('setLoading', false);
	}
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}