<template>
  <div class="pagination">
    <!-- 上 -->
    <el-button class="btn" :disabled="pageNo == 1" @click="getPageNo(pageNo - 1)">
      <svg-icon icon-class="left"/>
    </el-button>

    <div class="pager">
      <span>{{ start }}-{{end}} {{$t('of')}} {{total_num}}</span>
    </div>


    <!-- 下 -->
    <el-button class="btn" :disabled = "pageNo === totalPage" @click="getPageNo(pageNo + 1)">
      <svg-icon icon-class="right"/>
    </el-button>


  </div>
</template>

<script>

export default {
  name: "Pagination",
  props: ["pageNo", "pageSize", "total"],
  computed: {
    start(){
      return (this.pageNo - 1) * this.pageSize + 1
    },
    end(){
      return this.pageNo * this.pageSize > this.total ? this.total : this.pageNo * this.pageSize
    },
    total_num(){
      if (this.total>1000000){
        return `${(this.total/1000000).toFixed(1)}M`
      }else if (this.total>1000){
        return `${(this.total/1000).toFixed(1)}K`
      }else {
        return this.total
      }
    },
    //计算出总页数
    totalPage() {
      //向上取整
      return Math.ceil(this.total / this.pageSize);
    },
  },
  methods: {
    getPageNo(val) {
      //自定义事件子页面向父页面传参，计算属性值
      this.$emit('getPageNo', val)
    },

  }
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding:10px;
  .btn{
    width: 32px;
    height: 32px;
    border: 0;
    background: transparent;
    border-radius: 8px;
    .svg-icon{
      font-size: 12px;
      fill:$color-black;
    }
  }
  .btn:hover{
  //  background:$color-gray-0;
    .svg-icon{
  //    fill: $color-blue-3;
    }
  }
  .btn:disabled{
    background:transparent;
    .svg-icon{
      fill: $color-gray-0;
    }
  }

  .pager{
    margin:0 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.03em;
    text-align: left;
    color:$color-gray-1;

  }

}
</style>

