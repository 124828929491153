import * as accountAPI from '@/api/account'
import {setAccount,getAccount,setSubList,getSubList} from "@/utils/auth";


const state = {
    usage: {},
    account: getAccount() ? JSON.parse(getAccount()) : {},
    subList: localStorage.getItem('subList') ? JSON.parse(localStorage.getItem('subList')) : [],
    creditCardList: [],
    invoiceList: [],
    profileUsers: []
}

const mutations = {
    setProfileUsers(state, profileUsers) {
        state.profileUsers = profileUsers
    },
    setUsage(state, usage) {
        state.usage = usage
    },
    setAccount(state, account) {
        setAccount(JSON.stringify(account))
    },
    setSubList(state, subList) {
        localStorage.setItem('subList',JSON.stringify(subList))
    },
    setCreditCardList(state, creditList) {
        state.creditCardList = creditList
    },
    setInvoiceList(state, invoiceList) {
        state.invoiceList = invoiceList
    }
}

const actions = {
    async getProfile(context,data) {
        const res = await accountAPI.getProfile(data)
        context.commit('setAccount', res.data)
        return res.data
    },
    async getUsage(context,data) {
        const usage = await accountAPI.getUsage(data)
        context.commit('setUsage', usage.data)
        return usage.data
    },
    async updateUser(context, data) {
        const res = await accountAPI.updateUser({...data,"access_token":context.rootState.user.token})
        return res.data
        context.commit('setAccount', data)
    },
    async updatePassword(context, data) {
        const res = await accountAPI.updatePassword({...data,"access_token":context.rootState.user.token})
        return res.data
    },
    /*--------------profile-user-------------------*/
    async getUserList(context,data) {
        const res = await accountAPI.getProfileUsers(data)
        context.commit('setProfileUsers', res.data)
        return res.data
    },
    async inviteUsers(context,data) {
        const res = await accountAPI.inviteProfileUser(data)
        return res.data
    },
    async deleteUsers(context,data) {
        const res = await accountAPI.deleteUser(data)
        return res.data
    },

    /*------------订阅------------------ */
    async getSubscription(context,data) {
        const res = await accountAPI.getSubscription(data)
        console.log('res',res);
        context.commit('setSubList', res)
        return res
    },
    /*--订阅grade--*/
    async upgradeSubscription(context, data) {
        const res = await accountAPI.upgradeSubscription(data)
        return res.data
    },
    /*--购买credit--*/
    async purchaseCredit(context, data) {
        const res = await accountAPI.purchaseCredit(data)
        return res.data
    },

    /*------------billing------------------*/
    /*--增加信用卡--*/
     async addCreditCard(context, data) {
         const res = await accountAPI.addCredit(data)
     },
    async getCreditCard(context,data) {
        const res = await accountAPI.getPaymentMethods(data)
        context.commit('setCreditCardList', res.data)
        return res.data
    },
    /*--更新（删除）信用卡--*/
    async deleteCreditCard(context, data) {
        const res = await accountAPI.deleteCredit(data)
        return res.data

    },
    /*--获取invoice--*/
    async getInvoice(context,data) {
        const res = await accountAPI.getInvoices(data)
        context.commit('setInvoiceList', res.data)
        return res.data
    },
    /*----下载发票----*/
    async downloadInvoice(context, data) {
        const res = await accountAPI.downloadInvoice(data)
        return res.data
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}