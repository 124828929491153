import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}//解决路由重复点击报错问题

Vue.use(VueRouter)

import dashboard from '@/views/dashboard'

export const routes = [
  {
    path: '/login',
    name:'login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/signup',
    name:'Signup',
    component: () => import('@/views/signup'),
  },
  {
    path: '/forgot',
    name:'Forgot',
    component: () => import('@/views/forgot'),
  },

  {
    path: '/',
    component: dashboard,
    redirect:{name:'people'},
    children: [
     /* {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home'),
        meta: {title: 'Home' },
      },*/
      {
        path: 'search',
        name: 'Search',
        component: () => import('@/views/search'),
        redirect:{name:'people'},
       // redirect:{name:'company'},
        meta: {title: 'Search' },
        children: [
          {
            path: 'people',
            name: 'people',
            component: () => import('@/views/search/people'),
            meta: {title: 'Search' },
          },
          {
            path: 'company',
            name: 'company',
            component: () => import('@/views/search/companies/index'),
            meta: {title: 'Search' },
          }
            ]
      },
      {
        path: 'intent-signals',
        name: 'intent-signals',
        component: () => import('@/views/intent-signals'),
        redirect:{name:'intent'},
        meta: {title: 'Intent Signals' },
        children: [
          {
            path: 'intent',
            name: 'intent',
            component: () => import('@/views/intent-signals/website-intent'),
            meta: {title: 'Intent Signals' },
          },
          {
            path: 'market',
            name: 'market',
            component: () => import('@/views/intent-signals/market-intent'),
            meta: {title: 'Intent Signals' },
          },
        ]
      },
      {
        path: 'sequence',
        name: 'sequence',
        component: () => import('@/views/sequence'),
        meta: {title: 'Sequence' }
      },
      {
        path: 'tools',
        name: 'tools',
        component: () => import('@/views/tools'),
        meta: {title: 'Tools' }
      },
     /* {
        path: 'users',/!*需要传递参数*!/
        name: 'users',
        component: () => import('@/views/users'),
        meta:{title:'Users'},
      },*/
      /*------setting相关路由-------*/
      {
        path: 'settings',
        name:'settings',
        component:  () => import('@/views/setting/index'),
        redirect:{name:'account'},
        meta:{title:'Setting'},
        children: [
          {
            path: 'account',
            name:'account',
            component: () => import('@/views/setting/account'),
            meta:{index:'1'},
          },
          {
            path: 'teams',
            name: 'teams',
            component: () => import('@/views/setting/teams'),
            meta:{index:'1'},
          },
          {
            path: 'current-usage',
            name:'currentUsage',
            component: () => import('@/views/setting/current-usage'),
            meta:{index:'1'},
          },
          {
            path: 'upgrade',
            name:'upgrade',
            component: () => import('@/views/setting/upgrade'),
            meta:{index:'1'},
          },

          {
            path: 'billing',
            name:'billing',
            component: () => import('@/views/setting/billing'),
            meta:{index:'1'},
          },
          {
            path: 'integrations',
            name:'integrations',
            component: () => import('@/views/setting/integrations'),
            meta:{index:'2'},
          },
        ]
      },
      {
        path: '/company-detail/:domain_id',
        name: 'company-detail',
        meta:{title:'companyDetail'},
        component: () => import('@/views/search/companies/company-detail'),

      },
    ]
  },

  {
    path: '/reset',
    name:'Reset',
    component: () => import('@/views/reset/reset'),
  },

  /*----------404--------------*/
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },



]

const router = new VueRouter({
  routes
})


export default router
