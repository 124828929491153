import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import company from './modules/company'
import account from './modules/account'
import intent from './modules/intent'
import contact from './modules/contact'
import people from './modules/people'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    people,
    company,
    account,
    intent,
    contact,
  },
  getters
})
export default store