import * as ComObj from "@/api/company";
 const _ = require('lodash');



const state = {
    searchCompanyList:[],//搜索公司列表
    total:0,
    savedList:[],//已保存的公司
    companyGroup:[],//公司saved分组
    companyLists:[],
    mediaList:localStorage.getItem('mediaList')?JSON.parse(localStorage.getItem('mediaList')):[],
    locationList:localStorage.getItem('locationList')?JSON.parse(localStorage.getItem('locationList')):[],
    techList:[],
    techCategoryList:localStorage.getItem('techCategoryList')?JSON.parse(localStorage.getItem('techCategoryList')):[],
    industryList:localStorage.getItem('IndustryList')?JSON.parse(localStorage.getItem('IndustryList')):[],
   /* specialtyList:localStorage.getItem('SpecialtyList')?JSON.parse(localStorage.getItem('SpecialtyList')):[],*/
    isLoading:false,
    isShow:true, //控制filter是否隐藏
    clearAll:false,//控制是否清空
    savedSearch:[], //save search 中的SaveList
    isStopSearch:false,//控制是否停止搜索
    selected: {
        locations:[],
        verticals:[],
        categories:[],
        technologies:[],
        company_groups: [],
        companies:[],
        social_media:[],
        company_name:'',
        employees:[],
        founded_dates:[],
        revenues:[],
        page: 1,
        per_page: parseInt(process.env.VUE_APP_PER_PAGE),
        profile_id: '',
    },
}

const mutations = {
    setCollapse(state){
        state.isShow = !state.isShow
    },
    setClear(state){
        state.clearAll = true
    },
    setLoading(state,val){
        state.isLoading = val
    },
    /*------------综合搜索----------------*/
    setSelected(state,val){
        let id= this.state.user.currentId.profile_id
        let temp = {...state.selected, page: 1,...val,'profile_id':id}
        if (_.isEqual(state.selected,temp)){
            state.isStopSearch = true
        }else{
            state.selected = temp
        }
    },
    setIsStopSearch(state,val){
        state.isStopSearch = val
    },
    searchCompanyList(state,List){
        state.searchCompanyList = List.companies
        state.total = List.pagination.total_entries
        state.savedList = List.companies && List.companies.filter(item=>{
            if (item.saved_lists !== null){
                return item
        }})
    },
    setLocationList(state,locationList){
        localStorage.setItem('locationList',JSON.stringify(locationList))
    },
    setIndustryList(state,industryList){
        localStorage.setItem('IndustryList',JSON.stringify(industryList))
    },
    setSpecialityList(state,specialityList){
        localStorage.setItem('SpecialityList',JSON.stringify(specialityList))
    },
    setTechList(state,techList){
        state.techList = techList
    },
    setTechCategoryList(state,techCategoryList){
        localStorage.setItem('techCategoryList',JSON.stringify(techCategoryList))
    },
    setCompanyGroup(state,companyList){
       state.companyGroup = companyList
    },
    setCompanyList(state,companyList){
        state.companyLists = companyList
    },
    setMediaList(state,mediaList){
        localStorage.setItem('mediaList',JSON.stringify(mediaList))
    },
    setSearchSavedList(state,savedList){
        state.savedSearch = savedList
    }
}

const actions = {
    async searchCompanyList(context,val){
        await context.commit('setSelected',val)
        console.log(4);
        if (this.state.company.isStopSearch){
            context.commit('setIsStopSearch',false)
            return
        }
        console.log(5);
        const result = await ComObj.searchCompanyList(this.state.company.selected)
        context.commit('searchCompanyList',result.data)
        return result.data
    },
    /*---------------推荐公司列表----------------------*/
    async getCompanyList(context,val){
        const CompanyList = await ComObj.getCompanyList({"company_name":val})
        context.commit('setCompanyList',CompanyList.data)
        return CompanyList.data
    },
    async getIndustry(context,val){
        const IndustryList = await ComObj.getIndustry()
        context.commit('setIndustryList',IndustryList.data)
        return IndustryList.data
    },
    async getSpeciality(context,val){
        const SpecialityList = await ComObj.getSpeciality()
        context.commit('setSpecialityList',SpecialityList.data)
        return SpecialityList.data
    },

/*------------------------------------companyGroup-------------------------------------------------*/
    async getCompanyGroup(context){
        const result = await ComObj.getCompanyGroup({
            profile_id: this.state.user.currentId.profile_id,
        })
        context.commit('setCompanyGroup',result.data)
    },
/*----------------------------handle list-----------------------------------*/
    async addCompanyToGroup(context,val){
        await ComObj.addCompany(val)
    },
    async deleteCompany(context,val){
        const result = await ComObj.deleteCompany({...val,profile_id:this.state.user.currentId.profile_id})
        return result.data
    },
/*-------------------------------------------------------------------------------------------------*/
    async getMediaList(context){
        const result = await ComObj.getMediaList()
        context.commit('setMediaList',result.data)
        return result.data
    },
    async getLocationList(context){
        const result = await ComObj.getLocationList()
        context.commit('setLocationList',result.data)
        return result.data
    },
    async getTechList(context,data){
        const result = await ComObj.getTechList(data)
        context.commit('setTechList',result.data)
        return result.data
    },
    async getCategoryList(context,data){
        const result = await ComObj.getTechCategoryList(data)
        context.commit('setTechCategoryList',result.data)
        return result.data
    },

    /*--------------获取公司详情--------------------*/
    async getCompanyDetail(context,data){
        const result = await ComObj.getCompanyDetail(data)
        return result.data
    },
    /*-----根据groups返回domain ID------*/
    async getDomainIdByGroup(context,data){
        const result = await ComObj.getDomainIdByGroup({...data,profile_id:this.state.user.currentId.profile_id})
        return result.data
    },
    /*---------------export------------------*/
    async export(context,data){
        const result = await ComObj.exportCompany({...data,profile_id:this.state.user.currentId.profile_id})
        return result
    },
    /*---------------saved search------------------*/
   async getSaveList(context,data){
      const result = await ComObj.getSaveList({
            profile_id:this.state.user.currentId.profile_id,
           // "is_personal": data.isPersonal,
            "search_type_id": data.search_type_id
        })
       context.commit('setSearchSavedList', result.data)
       return result.data

    },
   async addSaveList(context,data){
       let type = data.search_type_id === 1 ? this.state.company.selected :this.state.people.selected
       let params = data.params ? data.params : type
        await ComObj.addSaveList({
            "profile_id":this.state.user.currentId.profile_id,
            "saved_search_name":data.saved_search_name,
            "is_personal": data.is_personal,
            "search_type_id": data.search_type_id,
            "is_pinned": data.is_pinned,
            "parameters":params
        })
    },
    async deleteSaveList(context,data){
        await ComObj.deleteSaveList(data)
    },
    async updateSaveList(context,data){
        let payload = {}
        data.params ? payload = {
            "profile_id":this.state.user.currentId.profile_id,
            "saved_searches": data.saved_searches,
            "saved_search_name": data.saved_search_name,
            "is_personal": data.is_personal,
            "is_pinned": data.is_pinned,
            "parameters":data.params,
        } : payload = {
            "profile_id":this.state.user.currentId.profile_id,
            "saved_searches": data.saved_searches,
            "saved_search_name": data.saved_search_name,
            "is_personal": data.is_personal,
            "is_pinned": data.is_pinned,
            "parameters":data.search_type_id === 1 ? this.state.company.selected :this.state.people.selected
        }
        let result = await ComObj.updateSaveList(payload)
        return result.data
    }


}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}