import request from '@/utils/request';

export function getPeopleGroupList(data){
    return request({
        method:'post',
        url:'/saved/people_group',
        data
    })
};

export function getCompanyGroupList(data){
    return request({
        method:'post',
        url:'/saved/company_group',
        data
    })
};

export function getContactGroupList(data){
    return request({
        method:'post',
        url:'/saved/contact_group',
        data
    })
};

export function updateCompanyGroup(data){
    return request({
        method:'post',
        url:'/saved/company_group/update',
        data
    })
};

export function updateContactGroup(data){
    return request({
        method:'post',
        url:'/saved/contact_group/update',
        data
    })
};

export function updatePeopleGroup(data){
    return request({
        method:'post',
        url:'/saved/people_group/update',
        data
    })
};

export function deletePeopleGroup(data) {
    return request({
        method:'delete',
        url:'/saved/people_group/delete',
        data
    })
};

export function deleteContactGroup(data) {
    return request({
        method:'delete',
        url:'/saved/contact_group/delete',
        data
    })
};

export function deleteCompanyGroup(data) {
    return request({
        method:'delete',
        url:'/saved/company_group/delete',
        data
    })
};

export function addContactList(data) {
    return request({
        method:'post',
        url:'/saved/contacts/add',
        data
    })
};

export function addCompanyList(data) {
    return request({
        method:'post',
        url:'/saved/companies/add',
        data
    })
};

export function addPeopleList(data) {
    return request({
        method:'post',
        url:'/saved/peoples/add',
        data
    })
};

export function createPeopleGroup(data) {
    return request({
        method:'post',
        url:'/saved/people_group/create',
        data
    })
};

export function createContactGroup(data) {
    return request({
        method:'post',
        url:'/saved/contact_group/create',
        data
    })
};

export function createCompanyGroup(data) {
    return request({
        method:'post',
        url:'/saved/company_group/create',
        data
    })
};

export function deleteCompany(data) {
    return request({
        method:'delete',
        url:'/saved/companies/delete',
        data
    })
};

export function deletePeople(data) {
    return request({
        method:'delete',
        url:'/saved/peoples/delete',
        data
    })
}

export function deleteContact(data) {
    return request({
        method:'delete',
        url:'/saved/contacts/delete',
        data
    })
}

