import * as people from "@/api/people";
const _ = require('lodash');




const state = {
    searchPeopleList:[],//搜索公司列表
    peopleGroup:[],//人群saved分组
    total:0,
    savedList:[],//已保存的人
    peopleLists:[],//人员列表
    contactList:[],//location contact列表
    managementLevels:localStorage.getItem('managementLevels')?JSON.parse(localStorage.getItem('managementLevels')):[],//管理级别
    departments:localStorage.getItem('departments')?JSON.parse(localStorage.getItem('departments')):[],//部门
    departmentFunctions:localStorage.getItem('departmentFunctions')?JSON.parse(localStorage.getItem('departmentFunctions')):[],//部门Function
    titles:[],//title
    names:[],//name
    isLoading:false,
    isShow:true, //控制filter是否隐藏
    clearAll:false,//控制是否清空
    isStopSearch:false,//控制是否停止搜索
    selected: {
        search_term:'',
        people_groups:[],
        people_name:'',
        people_titles:[],
        departments:[],
        department_functions:[],
        management_levels:[],
        employees:[],
        verticals:[],
        people_locations:[],
        company_locations:[],
        companies:[],
        technologies:[],
        categories:[],
        per_page: parseInt(process.env.VUE_APP_PER_PAGE),
        profile_id: '',
    },
}
const mutations = {
    setCollapse(state){
        state.isShow = !state.isShow
    },
    setClear(state){
        state.clearAll = true
    },
    setLoading(state,val){
        state.isLoading = val
    },

    /*-------------综合搜索----------------*/
    setSelected(state,val){
        let id = this.state.user.currentId.profile_id
        let temp = {...state.selected, page: 1,...val,'profile_id':id}
        if (_.isEqual(state.selected,temp)){
            state.isStopSearch = true
        }else{
            state.selected = temp
        }
    },
    setIsStopSearch(state,val){
        state.isStopSearch = val
    },
    searchPeopleList(state,List){
        state.searchPeopleList = List.peoples
        state.total = List.pagination.total_entries
        state.savedList = List.peoples && List.peoples.filter(item=>{
            if (item.saved_lists !== null){
                return item
            }})
    },
    /*-------------人员saved分组----------------*/
    setPeopleGroup(state,peopleList){
        state.peopleGroup = peopleList
    },
    setManagementLevels(state,List){
        localStorage.setItem('managementLevels',JSON.stringify(List))
    },
    setDepartments(state,List){
        localStorage.setItem('departments',JSON.stringify(List))
    },
    setDepartmentFunctions(state,List){
        localStorage.setItem('departmentFunctions',JSON.stringify(List))
    },
}
const actions = {
    setClear(context){
        context.commit('setClear')
    },
    /*-------------综合搜索----------------*/
    async searchPeopleList(context,val){
        await context.commit('setSelected',val)
        if (this.state.people.isStopSearch){
            context.commit('setIsStopSearch',false)
            return
        }
        const result = await people.searchPeopleList(this.state.people.selected)
        context.commit('searchPeopleList',result.data)
        return result.data
    },
    /*-------------人员saved分组----------------*/
    async getPeopleGroup(context){
        let id = this.state.user.currentId.profile_id
        const result = await people.getPeopleGroup({'profile_id': id})
        context.commit('setPeopleGroup',result.data)
    },
    /*-------------人员管理级别----------------*/
    async getManagementLevels(context){
        const result = await people.getManagementLevels()
        context.commit('setManagementLevels',result.data)
        return result.data
    },
    /*-------------人员部门----------------*/
    async getDepartments(context){
        const result = await people.getDepartments()
        context.commit('setDepartments',result.data)
        return result.data
    },
    /*-------------部门Function----------------*/
    async getDepartmentFunctions(context){
        const result = await people.getDepartmentFunction()
        context.commit('setDepartmentFunctions',result.data)
        return result.data
    },
    /*-------------人员name----------------*/
    async getNames(context,val){
        const result = await people.getNames(val)
        context.commit('setNames',result.data)
    },
    /*-------------人员表格请求Email地址----------------*/
    async getEmail(context,val){
        const result = await people.getEmail({...val,profile_id:this.state.user.currentId.profile_id})
    },
    /*-------------delete item from group----------------*/
    async deletePeople(context,val){
        const result = await people.deletePeople({...val,profile_id:this.state.user.currentId.profile_id})
    },
    /*-----根据groups返回domain ID------*/
    async getDomainIdByGroup(context,data){
        const result = await people.getDomainIdByGroup({...data,profile_id:this.state.user.currentId.profile_id})
        return result.data
    },
    /*---------------export------------------*/
    async export(context,data){
        const result = await people.exportCompany({...data,profile_id:this.state.user.currentId.profile_id})
        return result
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}