export default {
//login
        loading:'Loading...',
        SignUp:'Sign Up',
        LoginSuccess:'Login successfully',
        LoginOut:'Logged out successfully',
        email:'Email',
        password:'Password',
        logIn:'Log In',
        WelcomeBack:'Welcome Back',
        PleaseEnterYourEmailAndPassword:'Please enter your email and password',
        CharactersRequired:'8+ characters required',
        keepMeSignedIn:'Keep me signed in',
        forgetPassword:'Forget password',
        dontHaveAnAccount:'Do not have an account? ',
        signUpNow:'Sign Up Now',
        AllRightsReserved:'All Rights Reserved',
        privacyPolicy:'Privacy Policy',
        and:'and',
        termsOfService:'Terms of Service',
        Name:'Name',
        viewAll: 'view all',
        fold: 'fold',

//signup
        company:'Company',
        companyName: 'Company Name',
        signUp:'Sign Up',
        alreadyHaveAnAccount:'Already have an account? ',
        getStarted:'Get started! Empower your business with our platform',
        signUpSuccess:'Sign up successfully',


//foget
        noWorries:'No worries, enter your registered email, and we will send you reset instructions',


//reset
        reset:'Reset Password',
        forgotPassword:'Forgot Password',
        RequestPasswordReset:'Request Password Reset',
        backToLogin:'Back to Login',
        verifyPassword: 'Verify Password',
        EnterANewPasswordFor: 'Enter a new password for example@gmail.com',
        ResetPassword: 'Reset Password',
        newPassword: 'New Password',

//top-nav
        Home:'Home',
        Search:'Search' ,
        Prospects:'Prospects',
        filterYourSearchToFindHighlySpecific:'Filter your search to find highly specific',
        groupsOfTargetedLeads:'groups of targeted leads',
        filterYourSearchToFindPreciselyTargeted:'Filter your search to find precisely targeted',
        listsOfCompanies:'lists of companies',
        transformAnonymousWebsiteTrafficInto:'Transform anonymous website traffic into',
        b2bLeadsWithRealtimeIdentification:'B2B leads with real-time identification',
        discoverAndConnectWithPotentialBuyers:'Discover and connect with potential buyers',
        activelySeekingYourSolutionToday:'actively seeking your solution today',
        Intent_Signals:'Intent Signals',
        Website_Intent:'Website Intent',
        Market_Intent:'Market Intent',
        Sequence:'Sequence',
        Tools:'Tools',
        technologyLookup:'Technology Lookup',
        domainLookup:'Domain Lookup',
        emailVerification:'Email Verification',
        upgrade:'Upgrade',
        helpCenter:'Help Center',
        contactUs:'Contact Us',
        hello:'Hello',
        clickToChange:'Click to change',
        yourProfile:'Your Profile',
        currentUsage:'Current Usage',
        upgradePlan:'Upgrade Plan',
        integrations:'Integrations',
        featureRequests:'Feature Requests',
        change:'Change',
        en:'English',
        cn:'中文（简）',
        tw:'中文（繁）',
        logout:'Log Out',
        switchLanguage:'Switch Language successfully',


//not available
        ComingSoon:'Coming Soon!',
        TheFeatureIscurrentlyUnder:'The feature is currently under',
        development:'development',
        weAppreciateYourPatienceAsWeWorkToImprove:'We appreciate your patience as we work to improve',
        yourExperience:'your experience',
        inTheMeantimeIfYouHaveAnyQuestionsOrNeed:'In the meantime, if you have any questions or need',
        assistancePleaseContactOurSupportTeam:'assistance, please contact our support team',
        contactSupport: 'Contact Support',
        ok: 'OK',
        thankYouForBeingAValuedUserOfOurPlatform: 'Thank you for being a valued user of our platform.',

//search-company
        people:'People',
        companies:'Companies',
        clearAll: 'Clear All',
        searchProspects: 'Search Prospects, Keywords,...',
   //actions
        isFoundIn: 'is found in ',
        isNotFoundWithinAnyLists: 'is not found within any lists',
        l: ' lists',
        remove: 'Remove',
        saveToLists: 'Save to Lists',
        DeleteFailed: 'Delete Failed',
//delete dialog
        areYouSureYouWantToDeleteThisRecord: 'Are you sure you want to delete this record?',
        proceedingWithDeletionWillResultInPermanentRemovalOfTheList: 'Proceeding with deletion will result in permanent removal of the list',
        delete: 'Delete',
        byCancelingYouEnsureThatNoListsWillBeRemoved: 'By canceling, you ensure that no lists will be removed',
//export dialog
        holdOnWereGeneratingYourExport: "Hold on, we're generating your export",
        UseThisButtonOnlyIfYouWishToCancelYourAction: 'Use this button only if you wish to cancel your action',
//save search pop
        saveSearch: 'Save Search',
        newSaveSearch: 'New Save Search',
        UpdateSavedSearch: 'Update Saved Search',
        enterNewSearchName: 'Enter New Search Name',
        visibility: 'Visibility',
        manageWhoCanSeeYourSavedSearch: 'Manage who can see your saved search',
        private: 'Private',
        onlyYouCanSeeYourSavedSearch: 'Only you can see your saved search',
        everyoneCanSeeYourSavedSearch: 'Everyone can see your saved search',
        pinSavedSearch: 'Pin Saved Search',
        findSavedSearch: 'Find saved search',
        enterSavedSearchName: 'Enter saved search name',
        duplicate: 'Duplicate',
        update: 'Update',
        noSavedSearches: 'No Saved Searches',
        RemoveCreditCard: 'Remove Credit Card',
        enterATitleForYourCard: 'Enter a title for your card',
        enterYourCardNumber: 'Enter your card number',
        digit: '3-digit',
        provideYourCompleteAddress: 'Provide your complete address',
        selectYourCountry: 'Select your country',



    //companyLink\contactBtn\functionBtn\default
        more: 'More',
        verified: 'Verified',
        GetEmailSuccessfully: 'Get Email Successfully',
        CopySuccessfully: 'Copy Successfully',
        recorded: 'Recorded',
        implement: 'Implement',
        sequence: 'Sequence',
        of: 'of',
        toAssistYouEffectivelyWeRequireFurtherDetailsAbout: 'To assist you effectively, we require further details about your search preferences. Please utilize the filter options in the left panel to specify your desired criteria, enabling us to refine the search results and provide you with targeted individuals that meet your requirements.',

  //filter
        all: 'All',
        my: 'My',
        team: 'Team',
        lists: 'Lists',
        employees: 'Employees',
        technologies: 'Technologies',
        socialmedia: 'Social Media',
        enterSocialMedia: 'Enter Social Media',
        noResultsFound: 'No results found',
        searchCompanies:'Search Companies',
        filters:'Filters',
        enterCompanies:'Enter Companies',
        accountHqLocation:'Account HQ Location',
        CityStateCountryZIP:'City/State/Country/ZIP',
        enterLocations:'Enter Locations',
        revenue:'Revenue',
        chooseARangeOfRevenue:'Choose a range of revenue',
        ofRevenueIsUnknown:'of revenue is unknown',
        technology:'Technology',
        category:'Category',
        categories:'Categories',
        enterCategories:'Enter Categories',
        enterTechnologies:'Enter Technologies',
        enterIndustries:'Enter Industries',
        employee:'Employee',
        predefinedRange:'Predefined Range',
        ofEmployeesIsUnknown:'of employees is unknown',
        enterNames:'Enter Names',
        jobTitle:'Job Title',
        searchForAJobTitle:' Search for a Job Title',
        founded:'Founded',
        chooseARangeOfFoundedTime:'Choose a range of time',
        from:'From',
        to:'To',
        specialties:'Specialities',
        enterSpecialties:'Enter Specialties',
        location:'Location',
        total:'Total',
        department: 'Department',
        EnterDepartments: 'Enter Departments',
        JobTitle: 'Job Title',
        EnterJobTitles: 'Enter Job Titles',
        ManagementLevel: 'Management Level',
        EnterManagementLevels: 'Enter Management Levels',
        AccountHQ: 'Account HQ',
        ContactHQ: 'Contact HQ',
        departmentFunction: 'Department Function',
        EnterJobFunctions: 'Enter Job Functions',
        enterNamesAndPressTheEnterLKey: 'Enter names and press the Enter key...',
        EnterJobTitlesAndPressTheEnterLKey: 'Enter job titles and press the Enter key...',
        pleaseSelectRange: 'Please select a range',

//table
        selectAllCompanies:'Select All Companies',
        selected:'Selected',
        export:'Export',
        industry:'Industry',
        keywords:'Keywords',
        companyLocation:'Company Location',
        action: 'Action',
        PleaseEnterTheSelectedQuantity: 'Please enter the selected quantity',
        contact: 'Contact',
        Title: 'Title',
        selectThisPage: 'Select This Page',
        clearSelection: 'Clear Selection',
        selectNumberOfCompanies: 'Select number of companies',
        maxCompanies: 'Max companies',
        youCanOnlySelectAMaximumOf25Companies: 'You can only select a maximum of 25 companies.',
        UpgradeYourPlanNow: 'Upgrade your plan now!',
        viewPlans: 'View Plans',
        applySelection: 'Apply Selection',
        selectNumberOfProspects: 'Select number of prospects',
        maxProspects: 'Max prospects',
        youCanOnlySelectAMaximumOf25Prospects: 'You can only select a maximum of 25 prospects.',
        PleaseSelectTheDataToBeExported: 'Please select the data to be exported',
        overview: 'Overview',
        genericContacts: 'Generic Contacts',
        size: 'Size',
        contactLocation: 'Contact Location',


//account
    addCreditCard: 'Add Credit Card',
    zip: 'Zip',
    country: 'Country',
    state: 'State',
    city: 'City',
    address: 'Address',
    billingInformation: 'Billing Information',
    yourTeams: 'Your Teams',
    cvc: 'CVC',
    year: 'year',
    month: 'month',
    expirationDate: 'Expiration Date',
    creditCardNumber: 'Credit Card Number',
    cardInformation: 'Card Information',
    title: 'Title',
    fullName: 'Full Name',
    yourDetail: 'Your Detail',
    creditsToUse: 'credits to use.',
    youHave: 'You have',
    download: 'Download',
    buyMoreCredits: 'Buy more Credits',
    description: 'Description',
    topUpCredits: 'Top Up Credits',
    cancel: 'cancel',
    date: 'Date',
    invoiceHistory: 'Invoice History',
    confirmNewPass: 'Confirm new pass',
    creditsUsed: 'Credits used',
    newPass: 'New pass',
    youHaveNotEnteredAnyCreditCardInformationYet: 'You have not entered any credit card information yet.',
    monthlyCredit: 'Monthly Credit',
    oldPass: 'Old pass',
    cardNumber: 'Card Number:',
    changePassword: 'Change Password',
    creditUsage: 'Credit Usage',
    typeTheNewTitle: 'Type the new title',
    save: 'Save',
    monthlyTotal: 'Monthly Total',
    addMoreCredits: 'Add more credits',
    addonCredits: 'Add-On Credits',
    featureRequest: 'Feature Request',
    creditsMo: '{credit} Credits / mo',
    apiIntegrations: 'API Integrations',
    creditsInYourPlan: 'Credits (In your Plan)',
    settings: 'SETTINGS',
    phone: 'Phone',
    '1User': '1 user',
    users: 'Users',
    billing: 'Billing',
    monthlyQueryLimit: 'Monthly Query Limit',
    price: 'Price',
    whatsIncluded: 'What\'\'s Included',
    givenName: 'Given Name',
    profile: 'Profile & Teams',
    purchasePlan: 'Purchase Plan',
    accountOverview: 'ACCOUNT OVERVIEW',
    familyName: 'Family Name',
    dailyQueryLimit: 'Daily Query Limit',
    rateLimit: 'Rate Limit',
    youAreOnA:'You are on a',
    PlanAndYourCreditsWillRefreshOn:  'plan and your credits will refresh on',
    过期:'',
    searchSettings: 'Search Settings...',
    plan: 'Plan',
    accountInfo: 'Account Info',
    creditCardInformation: 'Credit Card Information',
    apiUsage: 'API Usage',
    mo:'/mo',
    perUserPerMonth:'per User Per Month',
    billedAnnually:'Billed Annually',
    dailyQuery:'Daily Query',
    monthlyQuery:'MonthlyQuery',
    currentPlan:'Current Plan',
    everythingInPlus:'Everything In Plus',
    mostPopular:'mostPopular',
    topUpToIncreaseYourCredits:'Top up to increase your credits',
    addCredits:'Add Credits',
    allPlansInclude:'All Plans Include',
    Amount:'Amount',
    credits:'Credits',
    purchaseSuccess:'Purchase credits successfully, your total credits are ',
    Loading:'Loading',
    Selected:'Selected',
    SelectPlan:'Select Plan',
    upgradeSuccess:'Plan switch successfully',
    purchaseFailed:'Purchase failed',
    upgradeFailed:'Plan switch failed',
    inviteSentSuccessfully:'Invite sent successfully',
    DeleteSuccessfully:'Delete Successfully',
    AccountupdatedSuccessfully:'Account updated successfully',
    AccountUpdateFailed:'Account Update Failed',
    PasswordUpdatedSuccessfully:'Password updated successfully',
    PasswordUpdateFailed:'Password Update Failed',
    teamDetails:'Team Details',
    teamName:'Team Name',
    changeTeam:'Change Team',
    clickToChangeTeam:'Click to change team',
    changeTeamSuccessfully:'Change team successfully',
    status:'Status',
    Action:'Action',
    pending:'Pending',
    current:'Current',
    AreYouSureYouWantToDeleteThisCreditCard:'Are you sure you want to delete this credit card?',
    AreYouSureYouWantToDeleteThisTeamMember:'Are you sure you want to delete this team member?',
    PasswordCannotBeEmpty:'Password cannot be empty',
    newUser:'New User',
    deleteUser:'Delete User',
    nickname:'Nickname',
    admin:'Admin',
    inviteYourTeam:'Invite Your Team',
    empowerEveryoneToGetMoreDealsDonefaster:'Empower everyone to get more deals done faster',
    addMultipleEmailAddressesSeparatedByCommas:'Add multiple email addresses separated by commas',
    sendInvites:'Send Invites',
    FirstName:'First Name',
    LastName:'Last Name',
    timezone:'Timezone',
    contactNo:'Contact No',
    controlYourTeamMembersAndTheirAccountAccessRightHere:'Control your team members and their account access right here.',
    pleaseEnterYourCurrentPasswordToChangeYourPassword:'Please enter your current password to change your password.',
    updatePassword:'Update Password',
    oldPassword:'Old Password',
    confirmNewPassword:'Confirm New Password',
    InviteYourTeam:'Invite Your Team',
    empowerEveryoneToGetMoreDealsDoneFaster:'Empower everyone to get more deals done faster.',
    enterEmailAddressesMultipleCommaseparated:'Enter email addresses (multiple comma-separated)',
    invoice:'Invoice',
    accountAdmin:'Account Admin',
    setAsDefault:'Set as Default',
    bankName:'Bank Name',
    expiry:'Expiry',
    viewAllYourPreviousInvoicesHere:'View all your previous invoices here.',
    BillingHistory:'Billing History',
    updateYourCreditCardInformation:'Update your credit card information.',
    ManageCreditCard:'Manage Credit Card',
    CardNumber:'Card Number',
    billingAddress:'Billing Address',
    yourCreditsWillRefreshOn:'Your credits will refresh on',
    perMonth:'per month',
    features:'Features',
    credits_mo:'Credits/mo',
    _mo:'/mo',
    seeOtherPlan:'See Other Plans',
    subscriptionTiers:'Subscription Tiers',
    experiencePricingThatsEasyToUnderstandAndAdaptable:"Experience pricing that's easy to understand and adaptable to your needs.",
    monthlyBilling:'Monthly Billing',
    annualBilling:'Annual Billing',
    forPlanCustomizationContactOurSalesTeam:'For plan customization, contact our sales team.',
    billedAnnually_user:'Billed Annually/user',
    seePlanComparison:'See Plan Comparison',
    topUp:'Top Up',
    changeTeamNameFailed:'Change team name failed',
    purchaseCreditsAtTheRateOf100PerCreditCustomize:'Purchase credits at the rate of $1.00 per credit. Customize your credit bundle based on your needs. ',
    totalPrice:'Total Price',
    credit:'Credit',





//company-Item
    // noCompanyFound: 'no company found',
    // contactData: 'Contact Data',
    // overview: 'Overview',
    // selectAll: 'Select All',
    // advancedSearch: 'Advanced search',
    // timeline: 'Timeline',
    // emails: 'EMAILS',
    // searchByContactsName: 'Search by contacts name',
    // statistics: 'Statistics',
    // pleaseTellUsABitAboutTheWebsiteYoudLikeToAdd: "Please tell us a bit about the website you'd like to add",
    // Users:'Users',
    // newUser:'New User',
    // deleteUser:'Delete User',
    // admin:'Admin',
    // inviteYourTeam:'Invite Your Team',
    // empowerEveryoneToGetMoreDealsDonefaster:'Empower everyone to get more deals done faster',
    // addMultipleEmailAddressesSeparatedByCommas:'Add multiple email addresses separated by commas',
    // sendInvites:'Send Invites',
    // yourTeams:'Your Teams',
    // AreYouSureYouWantToDeleteThisTeamMember:'Are you sure you want to delete this team member？',
    // lists:'Lists',
    // socialmedia:'Social Media',
    // enterSocialMedia:'Enter Social Media',
    // chooseARangeOfRevenue:'Choose a range of revenue',
    // chooseARangeOfEmployee:'Choose a range of employee',
    // searchPeople:'Search People',
    // saved:'Saved',

// Intent
    intentOverview: "Overview",
    intentAnalytic: "Analytic",
    intentFilter: "Filter",
    searchCompany:'Search companies, website, linkedin...',
    savedCompanyWebsite: 'Saved company website',
    companiesFound: 'Companies found', 
    companyFound: 'Company found',
    filterList: {
      technologies: 'Technology',
      time: 'Time',
      locations: 'AccountLocation',
      verticals: 'Industry',
      pageview_greater_than: 'Pageview',
      session_greater_than: 'Session',
      advertisements: 'Advertisement',
      company_groups: 'Lists',
    },
    employeeFilterList: {
        industry: 'Industry',
        technology: 'Technology',
        jobTitle: 'Job Title',
        company: 'Company',
        list: 'Lists',
        name: 'Name',
        size: 'Size',
        location: 'Location',
        jobFunction: 'Job Function'
    },
    selectLists:'Select Lists...',
    chooseARangeOfTime: 'Choose a range of time',
    greaterThan: 'Greater than',
    selectAdvertisement: 'Select Advertisement',
    // intentIndustry: 'Industry',
    tabActivity: 'Activity',
    tabOverview: 'Overview',
    tabEmployees: 'Employees',
    tabContacts: 'Generic Contacts',
    tabTechnologies: 'Technologies',
    intentStatistic: 'Statistic',
    intentCampaign: 'Campaign',
    newVisitors: 'New visitors',
    recurringVisitors: 'Recurring Visitors',
    mediocreLead: 'Mediocre lead',
    sessionsTracked: 'Sessions tracked',
    sessionTracked: 'Session tracked',
    lastSeenOn: 'Last seen on ',
    firstSeenOn: 'First seen on ',
    viewTrackedWebSession: 'View Tracked Web Session',
    webSession: 'Web Session',
    client: 'CLIENT',
    timeSpent: 'TIME SPENT',
    pageviews: 'Page Views',
    pageview: 'Page View',
    referrer: 'REFERRER',
    sessions: 'Sessions',
    session: 'Session',
    visited: 'Visited',
    AM: 'AM',
    PM: 'PM',
    companyDetails: 'Company Details',
    overviewList: {
        location: 'Location',
        industry: 'Industry',
        founded: 'Founded',
        ranking: 'Ranking',
        size: 'Size',
        revenue: 'Estimated Revenue',
        keywords: 'Keywords',
    },
    advancedSearch: 'Advanced Search',
    jobFunction: 'Job Function',
    employeeLocation: 'Location',
    concatLocation: 'Contact Location',
    contactEmail: 'Email',
    categoryAndTechnology: 'Category and Technology',
    firstDetected: 'First Detected',
    lastDetected: 'Last Detected',
    saved: 'Saved',
    addToContactList: 'Add to Contact List',
    noCampaignIdentified: 'No Campaign Identified',
    copyEmail: 'Copy Email',
    willBeSavedToTheFollowingSelectedLists: 'will be saved to the following selected lists:',
    addToLists: 'Add to Lists',
    noDataToDisplay: 'No Data to Display',
    intentFounded: 'FOUNDED',
    intentIndustry: 'INDUSTRY',
    intentRevenue: 'REVENUE',
    intentSize: 'SIZE',
    applyFilters: 'Apply Filters',
    youCanEditTheseFilters: 'You can edit these filters anytime to refine your results',

//     analytic: 'Analytic',
//     allLeads: 'All leads',
//     campaign: 'campaign',
//     medium: 'medium',
//     source: 'source',
//     term: 'term',
//     employees: 'Employees',
//     genericContacts: 'Generic Contacts',
//     technologies: 'Technologies',
//     campaigns: 'Campaigns',
    installB2BDirectLeadFinder:'Install B2B Direct Lead Finder on your site',
    addSite: 'Add Site',
    areYouSureYouWantToRemoveThisWebsite: 'Are you sure you want to remove this website?',
    Cancel: 'Cancel',
    installB2BLeadFinder:'Install B2B Lead Finder',
    siteUrl: 'Site Url',
    copyToClipboard: 'Copy to clipboard',
    pleaseSelectAContactFirst: 'please select a contact first',
    pasteCodeBelowIntoTheHead: 'Paste the code below into the',
    ofEveryPageYouWishToTrackVisitorOn: 'of every page you wish to track visitors on.',
    forWordpressWeRecommendInstalling: 'For Wordpress, we recommend installing the',
    pluginFromStefanoLissa: 'plugin from Stefano Lissa.',
    afterInstallingAndActivating: 'After installing and activating the plugin, navigate to',
    inYourWordPressDashboard: 'in your WordPress dashboard.',
    youWillSeeANumberOfTabs: "You’ll see a number of tabs in the plugin’s interface. Make sure you're in the default 'Head and footer' tab, then copy & paste the following code snippet:",
    settingHeaderAndFooter: 'Settings → Header and Footer',
    dontForgetToClearYourCache: "Don't forget to clear your cache",
    ifYouAreUsingAnyCaChing: 'If you are using any caching plugins such as',
    orSimilar:"or similar, make sure you clear the cache after installing the code snippet!",
    GTMshouldAlreadyBeInstalled: 'Google Tag Manager should already be installed on your site before you follow these instructions',
    ifYouDontHaveGTM: "If you don't have Google Tag Manager installed yet you can find out how in the ",
    GTMsupportArticleAboutInstall: 'Google Tag Manager support article about installing it',
    signInToGTMAndClick: 'Sign in to Google Tag Manager, and click on the Add a New Tag button. Next,',
    clickWhereItSays: 'click where it says',
    chooseATagTypeToBeginSetup: ' Choose a tag type to begin setup',
    andSelect: ' and select ',
    fromTheList: 'from the list. Then, Copy & Paste the code following code into the text field:',
    nextClick: 'Next, click ',
    giveTheTagAName: '. Give the tag a name and then click the Save button.',
    dontForgetToPublishYourChange: "Don't forget to publish your changes",
    afterCreateTheCustom: "After creating the Custom HTML tag, don't forget to ",
    yourChanges: ' your changes!',
    submitAndPublish: 'Submit and Publish',
    copySuccessfully: 'Copy Successfully',
    addToCompanyList: 'Add to company list',
    addToList: 'Add to list',
    enterOrCreateList: 'Enter or create lists',
    installCode: 'Install Code',
    wordpress: 'Wordpress',
    googleTagManager: 'Google Tag Manager',
    noVisitorsYet: 'No Visitors Yet',
    onceTheInsightsRollIn: "Once the insights roll in, you'll unlock valuable information about your visitors and more",
    noTechAreFound: 'No technologies are found',
    updateSuccessfully: 'Update Successfully',
    enterJobFunction: 'Enter Job Function',
    pinSuccessfully: 'Successfully topped',
    cancelPinSuccessfully: 'Successfully canceled topping',
    adsName: 'ads name',

    // analytic
    showNumber: 'show number',
    Website: 'Website',
    b2bTraffic: 'B2B Traffic',
    b2bReturningVsNew: 'B2B Returning VS New Visitors',
    visitors: 'Visitors',
    totalView: 'Total View',
    b2bPageViews: 'B2B PAGEVIEWS',
    totalSessions: 'Total Sessions',
    operatingSystem: 'Operating System',
    browser: 'Browser',
    device: 'Device',
    companySize: 'Company Size',
    audience: 'Audience',
    topIndustry: 'Top Industries',
    topCountries: 'Top Countries',
    topReferrals: 'Top Referrals',
    new: 'New',
    returning: 'Returning',
    countriesStatistics: 'Countries Statistics',
    topCompaigns: 'Top Campaigns',
    topPagesByVisits: 'Top Pages by Visits',
    topPagesByEngagement: 'Top Pages by Engagement',
    Referrals: 'Referrals',
    Pages: 'Pages',
    avgTime: "Avg Time",
    totalCompanies: 'TOTAL COMPANIES',
    b2bPageViews: 'B2B PAGE VIEWS',
    TotalSessions: 'TOTAL SESSIONS',
    viewCompanyList: 'View Companies List',
// mycontact
    prospects: 'Prospects',
    list: 'List',
    view: 'View',
    add: 'Add',
    newList: 'New List',
    savedLists: 'Saved Lists',
    searchList: 'Search list name',
    owner: 'Owner',
    records: 'Records',
    actions: 'Actions',
    lastModified: 'Last Modified',
    name: 'name',
    enterAListName: 'Enter a list name',
    pleaseSelectAListFirst: 'please select a list first',
    saveSuccessfully: 'Save Successfully',
    pleaseEnterAListName: 'please enter a list name',
    editList: 'Edit List',
    fromThisList:'from this list. Are you sure you want to continue',
    areYouSureYouWantToChange: 'Are you sure you want to change the list type to',
    // yes: 'Yes',
    noListCreated: 'No List Created',
    enhanceTheOrganization: 'Enhance the organization of your contacts and accounts within your target market. After creating a list, they will appear on this page.',
    createSuccessfully: 'Create Successfully',
    removeSuccessfully: 'Remove Successfully',
    createNewList: 'Create New List',
    groupTeam: 'Team',
    groupPersonal: 'Personal',
    edit: 'Edit',
    areYouSureYouWantToDeleteTheList: 'Are you sure you want to delete the list?',
    proceedingWithDeletion: 'Proceeding with deletion will result in permanent removal of the list.',
    byCanceling: 'By canceling, you ensure that no lists will be removed',  
    byCancelingYouEnsureThatNo: 'By canceling, you ensure that no',
    willBeRemoved: 'will be removed',
    groupPeople: 'people',
    groupCompany: 'company',
    myList: 'My List',
    teamList: "Team's List",
    resetAssured: "Rest assured, you have the flexibility to switch between different types as needed.",
    wontBeChanged: "By canceling, you ensure that this list type won't be changed",
    priority: 'Priority',
    proceedingWithDeletionWillResultInPeopleDelete: 'Proceeding with deletion will result in permanent removal of the people',
    proceedingWithDeletionWillResultInCompanyDelete: 'Proceeding with deletion will result in permanent removal of the company',
    areYouSureYouWantToRemoveCompanyFromList: '1',
    areYouSureYouWantToRemovePeopleFromList: '2',
    groupExisted: 'group already exists',
    sorryTheListIsCurrrntlyOpen: 'Sorry, the list is currently open. Are you sure you want to delete it?',
    // market-intent
    comingSoon: 'COMING SOON',
    weAreCurrentlyWorkingOnThisFeature: 'We are currently working on this feature and will launch soon',
    stayConnectedWithUs: 'Stay connected with us through our social media channels to receive updates.',
    selectAWebsite: 'Select a website',

//     companies: ''
//     show: 'Show',
//     peopleLists: 'People Lists',
//     companyLists: 'Company Lists',
//     contactLists: 'Contact Lists',
//     my: 'My',
//     team: 'Team',
//     all: 'All',
//     newList: 'New List',

//     confirm: 'Confirm',
//     yes: 'Yes',

//     delete: 'delete',
//     edit: 'edit',
//     enterANewListName: 'Enter a New List Name',
    
}