import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
import VueCodeMirror from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';

import App from './App'
import router from './router'
import store from './store'
import i18n from '@/locales'
import ECharts from 'vue-echarts'
import pagination from '@/components/pagination'
import Clipboard from 'v-clipboard'
import Loading from '@/components/Loading'



import '@/icons'

import '@/permission'





Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
  size: 'mini'
})


Vue.use(Clipboard)
Vue.use(VueCodeMirror)

Vue.component('v-chart', ECharts)
Vue.component('pagination',pagination)
Vue.component('Loading',Loading)

Vue.directive('focus', {
  inserted (el, binding, vnode) {
    // 聚焦元素
    el.querySelector('input').focus()
  }
})







Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
