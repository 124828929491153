export default {
//login
        loading: '加载中...',
        SignUp: '注册',
        LoginSuccess:'登录成功',
        LoginOut:'退出成功',
        email: '邮箱',
        password: '密码',
        logIn: '登录',
        WelcomeBack: '欢迎回来',
        PleaseEnterYourEmailAndPassword: '请输入您的邮箱和密码',
        CharactersRequired: '不少于8个字符',
        keepMeSignedIn: '保持登录',
        forgetPassword: '忘记密码',
        dontHaveAnAccount: '没有账号?',
        signUpNow: '立即注册',
        AllRightsReserved: '版权所有',
        privacyPolicy:'隐私',
        and:'和',
        termsOfService:'条款',
        Name:'姓名',
        viewAll: '查看全部',
        fold: '收起',
//signup
        company:'公司',
        companyName:'公司名称',
        signUp:'注册',
        alreadyHaveAnAccount:'已有账号',
        getStarted:'开始吧！ 通过我们的平台为您的业务赋能',
        signUpSuccess:'注册成功',
//foget
        noWorries:'不用担心，输入您的注册电子邮件，我们将向您发送重置说明',
//reset
        reset:'重置密码',
        forgotPassword:'忘记密码',
        RequestPasswordReset:'请求密码重置',
        backToLogin:'返回登录',
        verifyPassword: '验证密码',
        EnterANewPasswordFor: '输入新密码',
        ResetPassword: '重置密码',
        newPassword: '新密码',
//top-nav
        Home: '首页',
        Search: '搜索',
        Prospects: '客户',
        filterYourSearchToFindHighlySpecific:'筛选搜索以找到高度特定的目标潜在客户群',
        groupsOfTargetedLeads:'',
        filterYourSearchToFindPreciselyTargeted:'筛选搜索以找到精确定位的公司群',
        listsOfCompanies:'',
        transformAnonymousWebsiteTrafficInto:'将匿名网站流量转化为实时识别的B2B',
        b2bLeadsWithRealtimeIdentification:'潜在客户',
        discoverAndConnectWithPotentialBuyers:'积极发现并联系潜在买家寻找您今天的',
        activelySeekingYourSolutionToday:'解决方案',
        Intent_Signals: '趋势信号',
        Website_Intent: '网站趋势',
        Market_Intent: '市场趋势',
        Sequence: '序列',
        Tools: '工具',
        technologyLookup:'技术查询',
        domainLookup:'域名查询',
        emailVerification:'邮箱认证',
        upgrade: '升级',
        helpCenter: '帮助中心',
        contactUs: '联系我们',
        hello: '你好',
        clickToChange: '点击更改',
        yourProfile: '个人资料',
        currentUsage: '使用情况',
        upgradePlan: '升级计划',
        integrations: '集成',
        featureRequest: '特色请求',
        change: '更改',
        en:'英文',
        cn:'中文（简）',
        tw:'中文（繁）',
        logout: '退出',
        switchLanguage: '切换语言成功',

//not available
        ComingSoon:'即将推出!',
        TheFeatureIscurrentlyUnder:'目前该功能正在开发中',
        development:'',
        weAppreciateYourPatienceAsWeWorkToImprove:'我们感谢您的耐心等待，我们将努力提升',
        yourExperience:'您的体验',
        inTheMeantimeIfYouHaveAnyQuestionsOrNeed:'同时，如果您有任何疑问或需要帮助，',
        assistancePleaseContactOurSupportTeam:'请联系我们的支持团队',
        contactSupport: '联系支持',
        ok: '确定',
        thankYouForBeingAValuedUserOfOurPlatform: '感谢您成为我们平台的重要用户',


//search-company
        people:'人',
        companies:'公司',
        clearAll: '清除所有',
        searchProspects: '搜索客户、关键词...',
  //actions
        isFoundIn: '在',
        isNotFoundWithinAnyLists: '没有在任何列表中找到',
        l: '个列表中找到',
        remove: '移除',
        saveToLists: '保存到列表',

  //delete dialog
        areYouSureYouWantToDeleteThisRecord: '您确定要删除此记录吗？',
        proceedingWithDeletionWillResultInPermanentRemovalOfTheList: '继续删除将导致永久删除列表',
        delete: '删除',
        byCancelingYouEnsureThatNoListsWillBeRemoved: '取消将不会删除任何列表',
        DeleteFailed: '删除失败',
//export dialog
        holdOnWereGeneratingYourExport: "稍等，我们正在生成您的导出",
        UseThisButtonOnlyIfYouWishToCancelYourAction: '仅在您希望取消操作时使用此按钮',
//save search pop
        saveSearch: '保存搜索',
        newSaveSearch: '新保存搜索',
        UpdateSavedSearch: '更新保存搜索',
        enterNewSearchName: '输入新搜索名称',
        visibility: '可见性',
        manageWhoCanSeeYourSavedSearch: '管理谁可以看到您的保存搜索',
        private: '私人',
        onlyYouCanSeeYourSavedSearch: '只有您可以看到您的保存搜索',
        everyoneCanSeeYourSavedSearch: '每个人都可以看到您的保存搜索',
        pinSavedSearch: '将保存搜索固定在顶部',
        findSavedSearch: '查找保存搜索',
        enterSavedSearchName: '输入保存搜索名称',
        duplicate: '复制',
        update: '更新',
        noSavedSearches: '没有保存的搜索',





   //companyLink\contactBtn\functionBtn\default
        more: '更多',
        verified: '已认证',
        GetEmailSuccessfully: '获取邮箱成功',
        CopySuccessfully: '复制成功',
        recorded: '已记录',
        implement: '执行',
        sequence: '序列',
        of: '  总条数：',
        toAssistYouEffectivelyWeRequireFurtherDetailsAbout: '为了有效地帮助您，我们需要有关您搜索偏好的更多详细信息。请利用左侧面板中的筛选选项来指定您所需的条件，以便我们能够优化搜索结果,并为您提供满足您要求的目标群体资料。',


   //filter
        all: '全部',
        my: '我的',
        team: '团队',
        lists: '列表',
        employees: '员工',
        technologies: '技术',
        socialmedia: '社交媒体',
        enterSocialMedia: '请输入社交媒体',
        noResultsFound: '没有找到结果',
        enterCompanies: '请输入公司',
        searchCompanies: '搜索公司',
        filters: '筛选',
        accountHqLocation: '总部位置',
        CityStateCountryZIP:'城市/州/国家/邮编',
        enterLocations: '请输入位置',
        revenue:'营收',
        chooseARangeOfRevenue:'选择营收区间',
        ofRevenueIsUnknown:'营收未知',
        technology:'技术',
        category:'类别',
        categories:'类别',
        enterCategories:'请输入类别',
        enterTechnologies:'请输入技术',
        employee:'员工',
        predefinedRange:'预定义范围',
        ofEmployeesIsUnknown:'员工数量未知',
        enterNames:'请输入名称',
        jobTitle:'职位',
        searchForAJobTitle: '搜索职位',
        enterIndustries:'请输入行业',
        founded:'成立时间',
        chooseARangeOfFoundedTime:'选择时间区间',
        from:'从',
        to:'到',
        specialties:'特色',
        enterSpecialties:'请输入特色',
        location:'位置',
        total: '总数',
        department: '部门',
        EnterDepartments: '请输入部门',
        JobTitle: '职位',
        EnterJobTitles: '请输入职位',
        ManagementLevel: '管理层级',
        EnterManagementLevels: '请输入管理层级',
        AccountHQ: '公司地址',
        ContactHQ: '联系地址',
        departmentFunction: '部门职能',
        EnterJobFunctions: '请输入部门职能',
        enterNamesAndPressTheEnterLKey: '输入名称并按Enter键',
        EnterJobTitlesAndPressTheEnterLKey: '输入职位并按Enter键',
        pleaseSelectRange: '请选择范围',




//table
        selectAllCompanies: '选择所有公司',
        selected: '已选择',
        export: '导出',
        industry: '行业',
        keywords:'关键词',
        companyLocation: '公司位置',
        action: '操作',
        PleaseEnterTheSelectedQuantity: '请输入选择的数量',
        contact: '联系方式',
        Title: '职位',
        selectThisPage: '选择此页',
        clearSelection: '清除选择',
        selectNumberOfCompanies: '选择公司数量',
        maxCompanies: '最大公司数量',
        youCanOnlySelectAMaximumOf25Companies: '您最多只能选择25家公司。',
        UpgradeYourPlanNow: '立即升级您的计划！',
        viewPlans: '查看计划',
        applySelection: '应用选择',
        selectNumberOfProspects: '选择客户数量',
        maxProspects: '最大客户数量',
        youCanOnlySelectAMaximumOf25Prospects: '您最多只能选择25个客户。',
        PleaseSelectTheDataToBeExported: '请选择要导出的数据',
        overview: '概览',
        genericContacts: '联系方式',
        size: '规模',
        contactLocation: '联系地址',





//account
        addCreditCard:'添加信用卡',
        zip: '邮编',
        country: '国家',
        state: '州',
        city: '城市',
        address: '地址',
        billingInformation: '账单信息',
        yourTeams: '您的团队',
        cvc: 'CVC',
        year: '年',
        month: '月',
        expirationDate: '到期日',
        creditCardNumber: '信用卡号码:',
        cardInformation: '卡信息',
        title: '标题',
        fullName: '全名',
        yourDetail: '您的详细信息',
        creditsToUse: '待使用积分',
        youHave: '你有',
        download: '下载',
        buyMoreCredits: '购买更多积分',
        description: '描述',
        topUpCredits: '充值积分',
        cancel: '取消',
        date: '日期',
        invoiceHistory: '发票历史',
        confirmNewPass: '确认新密码',
        creditsUsed: '已使用积分',
        newPass: '新密码',
        youHaveNotEnteredAnyCreditCardInformationYet: '您尚未输入任何信用卡信息。',
        monthlyCredit: '每月积分',
        oldPass: '旧密码',
        cardNumber: '卡号',
        changePassword: '更改密码',
        creditUsage: '积分使用情况',
        typeTheNewTitle: '输入新标题',
        save: '保存',
        monthlyTotal: '每月总计',
        addMoreCredits: '添加更多积分',
        addonCredits: '附加积分',
        creditsMo: '积分/月',
        apiIntegrations: 'API集成',
        creditsInYourPlan: '您计划中的积分',
        settings: '设置',
        phone: '电话',
        '1User': '1个用户',
        users: '用户',
        billing: '账单',
        monthlyQueryLimit: '每月查询限額',
        price: '价格',
        whatsIncluded: '包括什么',
        givenName: '名',
        profile: '个人和团队',
        purchasePlan: '购买计划',
        accountOverview: '账户概述',
        familyName: '姓',
        dailyQueryLimit: '每日查询限額',
        rateLimit: '限制',
        youAreOnA:'您正在',
        PlanAndYourCreditsWillRefreshOn: '计划中，您的积分将在',
        过期:'更新',
        searchSettings: '搜索设置',
        plan: '计划',
        accountInfo: '账户信息',
        creditCardInformation: '信用卡信息',
        apiUsage: 'API使用情况',
        mo:'/月',
        perUserPerMonth:'每用户每月',
        billedAnnually:'按年计费',
        dailyQuery:'每日查询',
        monthlyQuery:'每月查询',
        currentPlan:'当前计划',
        everythingInPlus:'Everything In Plus',
        mostPopular:'最受欢迎',
        topUpToIncreaseYourCredits:'增加您的积分',
        addCredits:'购买积分',
        allPlansInclude:'所有计划均包括',
        Amount:'金额',
        credits:'积分',
        purchaseSuccess:'购买成功,您的总积分为',
        purchaseFailed:'购买失败',
        Loading:'加载中',
        Selected:'已选择',
        SelectPlan:'选择计划',
        upgradeSuccess:'计划切换成功',
        upgradeFailed:'计划切换失败',
        inviteSentSuccessfully:'邀请发送成功',
        DeleteSuccessfully:'删除成功',
        AccountupdatedSuccessfully:'账户更新成功',
        AccountUpdateFailed:'账户更新失败',
        PasswordUpdatedSuccessfully:'密码更新成功',
        PasswordUpdateFailed:'密码更新失败',
        Users:'用户',
        newUser:'新用户',
        deleteUser:'删除用户',
        admin:'管理员',
        inviteYourTeam:'邀请您的团队',
        empowerEveryoneToGetMoreDealsDonefaster:'授权每个人更快地完成更多交易',
        addMultipleEmailAddressesSeparatedByCommas:'添加多个电子邮件地址（用逗号分隔）',
        sendInvites:'发送邀请',
        AreYouSureYouWantToDeleteThisTeamMember:'您确定要删除此团队成员吗？',
        teamDetails:'团队详情',
        teamName:'团队名称',
        changeTeam:'切换团队',
        clickToChangeTeam:'点击更改团队',
        changeTeamSuccessfully:'切换团队成功',
        status:'状态',
        Action:'操作',
        pending:'待定',
        current:'当前',
        AreYouSureYouWantToDeleteThisCreditCard:'您确定要删除此信用卡吗？',
        PasswordCannotBeEmpty:'密码不能为空',
        nickname:'昵称',
        FirstName:'名',
        LastName:'姓',
        timezone:'时区',
        contactNo:'联系电话',
        controlYourTeamMembersAndTheirAccountAccessRightHere:'在此处管理您的团队成员及其帐户访问权限',
        pleaseEnterYourCurrentPasswordToChangeYourPassword:'请输入当前密码以更改密码',
        updatePassword:'更新密码',
        oldPassword:'旧密码',
        confirmNewPassword:'确认新密码',
        InviteYourTeam:'邀请您的团队',
        empowerEveryoneToGetMoreDealsDoneFaster:'授权每个人更快地完成更多交易',
        enterEmailAddressesMultipleCommaseparated:'输入电子邮件地址（多个用逗号分隔）',
        invoice:'发票',
        accountAdmin:'账户管理员',
        setAsDefault:'设为默认',
        bankName:'银行名称',
        expiry:'到期',
        viewAllYourPreviousInvoicesHere:'在此处查看您以前的所有发票',
        BillingHistory:'账单历史',
        updateYourCreditCardInformation:'更新您的信用卡信息',
        ManageCreditCard:'管理信用卡',
        CardNumber:'卡号',
        billingAddress:'账单地址',
        yourCreditsWillRefreshOn:'您的积分将在',
        perMonth:'每月',
        features:'特征功能',
        credits_mo:'积分/月',
        _mo:'/月',
        seeOtherPlan:'查看其他计划',
        subscriptionTiers:'订阅区',
        experiencePricingThatsEasyToUnderstandAndAdaptable:"体验易于理解和适应的定价",
        monthlyBilling:'月度计费',
        annualBilling:'年度计费',
        forPlanCustomizationContactOurSalesTeam:'有关定制计划的更多信息，请联系我们的销售团队',
        billedAnnually_user:'年度计费/用户',
        seePlanComparison:'查看计划比较',
        topUp:'充值',
        changeTeamNameFailed:'更改团队名称失败',
        purchaseCreditsAtTheRateOf100PerCreditCustomize:'按每积分1美元的价格购买积分，请根据您的需求定制积分包',
        totalPrice:'总价',
        credit:'积分',
        RemoveCreditCard: '删除信用卡',
        enterATitleForYourCard: '为您的卡输入标题',
        enterYourCardNumber: '输入您的卡号',
        digit: '3位数字',
        provideYourCompleteAddress: '提供您的完整地址',
        selectYourCountry: '选择您的国家',





// Intent
        intentOverview: "概览",
        intentAnalytic: "分析",
        intentFilter: "筛选",
        searchCompany:'搜索公司、网站、领英...',
        savedCompanyWebsite: '已保存公司的网站',
        companiesFound: '家公司',
        companyFound: '家公司',
        filterList: {
          technologies: '技术',
          time: '时间',
          locations: '账号位置',
          verticals: '行业',
          pageview_greater_than: '页面预览',
          session_greater_than: '会话',
          advertisements: '广告',
          company_groups: '列表',
        },
        employeeFilterList: {
          industry: '行业',
          technology: '技术',
          jobTitle: '职位',
          company: '公司',
          list: '列表',
          name: '名字',
          size: '规模',
          location: '位置',
          jobFunction: '工作职能'
        },
        selectLists:'请选择列表...',
        chooseARangeOfTime: '时间段选择',
        greaterThan: '大于',
        selectAdvertisement: '选择广告',
        // intentIndustry: '行业',
        tabActivity: '活动',
        tabOverview: '概览',
        tabEmployees: '员工',
        tabContacts: '通用联系人',
        tabTechnologies: '技术',
        intentStatistic: '统计',
        intentCampaign: '活动',
        newVisitors: '新访客',
        recurringVisitors: '常客',
        mediocreLead: '普通的潜在客户',
        sessionsTracked: '个跟踪会话',
        sessionTracked: '个跟踪会话',
        lastSeenOn: '最后查看',
        firstSeenOn: '首次查看',
        viewTrackedWebSession: '查看跟踪的网络会话',
        webSession: '网络会话',
        client: '客户端',
        timeSpent: '花费时间',
        pageviews: '浏览量',
        pageview: '浏览量',
        referrer: '来源',
        sessions: '会话',
        session: '会话',
        visited: '访问',
        AM: '早晨',
        PM: '下午',
        companyDetails: '公司详情',
        overviewList: {
            location: '位置',
            industry: '行业',
            founded: '创建时间',
            ranking: '排名',
            size: '规模',
            revenue: '收入',
            keywords: '关键词',
        },
        advancedSearch: '高级搜索',
        jobFunction: '工作职能',
        employeeLocation: '位置',
        concatLocation: '联系位置',
        contactEmail: '邮件',
        categoryAndTechnology: '类别和技术',
        firstDetected: '首次查看',
        lastDetected: '最后查看',
        saved: '已保存',
        addToContactList: '添加到联系人列表',
        noCampaignIdentified: '暂时没有活动',
        copyEmail: '复制邮箱',
        willBeSavedToTheFollowingSelectedLists: '将会被保存到下列选中的列表:',
        addToLists: '添加到列表',
        noDataToDisplay: '暂无更多数据',
        intentFounded: '创建时间',
        intentIndustry: '行业',
        intentRevenue: '收入',
        intentSize: '规模',
        applyFilters: '筛选',
        youCanEditTheseFilters: '您可以随时编辑这些选项以筛选结果',


//         analytic: '统计分析',
//         allLeads: '所有线索',
//         campaign: '活动',
//         medium: '媒体',
//         source: '来源',
//         term: '项目',
//         employees: '员工',
//         activities: '活动',
//         genericContacts: '通用联系人',
//         technologies: '技术',
//         campaigns: '活动',
        installB2BDirectLeadFinder:'在您的网站上安装 B2B Direct Lead Finder',
        addSite: '添加站点',
        areYouSureYouWantToRemoveThisWebsite: '您确定要移除这个网站吗？',
        Cancel: '取消',
        installB2BLeadFinder:'安装 B2B Lead Finder',
        pleaseTellUsABitAboutTheWebsiteYoudLikeToAdd: "请告诉我们您要添加的网站的一些信息",
        siteUrl: '站点网址',
        copyToClipboard: '复制到剪贴板',
        pleaseSelectAContactFirst: '请先选择一个联系人',
        pasteCodeBelowIntoTheHead: '将下面的代码粘贴到您希望跟踪访问者的每个页面的',
        ofEveryPageYouWishToTrackVisitorOn: '中',
        forWordpressWeRecommendInstalling: '对于 WordPress，我们建议安装来自 Stefano Lissa 的',
        pluginFromStefanoLissa: '插件。',
        afterInstallingAndActivating: '安装并激活插件后，导航到 WordPress 仪表板中的',
        inYourWordPressDashboard: '。',
        youWillSeeANumberOfTabs: '您会在插件界面中看到许多选项卡， 确保您位于默认的“页眉和页脚”选项卡中，然后复制并粘贴以下代码片段:',
        settingHeaderAndFooter: '设置 → 页眉和页脚',
        dontForgetToClearYourCache: "不要忘记清除缓存",
        ifYouAreUsingAnyCaChing: '如果你正在使用像',
        orSimilar:"之类的插件, 确保在安装代码段后已经清除缓存!",
        GTMshouldAlreadyBeInstalled: '在按照这些说明操作之前，您的网站上应该已经安装了 Google Tag Manager',
        ifYouDontHaveGTM: "如果您还没有安装 Google Tag Manager，您可以在",
        GTMsupportArticleAboutInstall: 'Google Tag Manager 支持的文章中了解如何安装它',
        signInToGTMAndClick: '登录 Google Tag Manager，然后点击添加新标签按钮，然后',
        clickWhereItSays: '点击',
        chooseATagTypeToBeginSetup: ' "Choose a tag type to begin setup" ',
        andSelect: '并从列表中选择 ',
        fromTheList: '。然后将以下代码复制并粘贴到文本字段中',
        nextClick: '然后点击 ',
        giveTheTagAName: '，为标签命名，然后单击“保存”按钮。',
        dontForgetToPublishYourChange: "不要忘记发布您的更改",
        afterCreateTheCustom: '创建 "Custom HTML" 标记后，不要忘记',
        yourChanges: '你的更改!',
        submitAndPublish: '提交并发布',
        copySuccessfully: '复制成功',
        addToCompanyList: '添加到公司列表',
        addToList: '添加到列表',
        enterOrCreateList: '输入或创建列表',
        installCode: '安装代码',
        wordpress: 'Wordpress',
        googleTagManager: 'Google Tag Manager',
        noVisitorsYet: '暂无浏览',
        onceTheInsightsRollIn: "一旦深入了解，您将解锁有关访客等的宝贵信息。",
        noTechAreFound: '暂无技术内容',
        updateSuccessfully: '更新成功',
        enterJobFunction: '请输入工作职能',
        pinSuccessfully: '置顶成功',
        cancelPinSuccessfully: '取消置顶成功',
        adsName: '广告名称',

// analytic
        showNumber: '展示条数',
        Website: '网站',
        b2bTraffic: 'B2B 贸易',
        b2bReturningVsNew: 'B2B 常客 V.S. 新用户',
        visitors: '访客',
        totalView: '总浏览量',
        b2bPageViews: 'B2B 浏览量',
        totalSessions: '全部会话',
        operatingSystem: '操作系统',
        browser: '浏览器',
        device: '设备',
        companySize: '公司规模',
        audience: '观众',
        topIndustry: '热门行业',
        topCountries: '热门国家',
        topReferrals: '热门推荐',
        new: '新用户',
        returning: '老用户',
        countriesStatistics: '国家统计',
        topCompaigns: '热门活动',
        topPagesByVisits: '浏览量分析',
        topPagesByEngagement: '参与度分析',
        Referrals: '推荐',
        Pages: '页面',
        avgTime: "平均时间",
        totalCompanies: '全部公司',
        b2bPageViews: 'B2B 页面浏览',
        TotalSessions: '全部会话',
        viewCompanyList: '查看全部公司',
// mycontact
        prospects: '前景',
        list: '列表',
        view: '查看',
        // delete: '删除',
        add: '添加',
        newList: '新建列表',
        savedLists: '已保存列表',
        searchList: '搜索列表名称',
        owner: '所有者',
        records: '记录',
        actions: '操作',
        lastModified: '最后修改',
        name: '姓名',
        enterAListName: '请输入列表名字',
        pleaseSelectAListFirst: '请先选择一个列表项',
        saveSuccessfully: '保存成功',
        pleaseEnterAListName: '请输入列表名称',
        editList: '编辑列表',
        fromThisList: '从列表中移除,您确定要继续吗',
        areYouSureYouWantToChange: '您确定要将列表类型更改为',
        // yes: '确定',
        noListCreated: '暂无列表',
        enhanceTheOrganization: '加强您在目标市场内联系人和帐户的组织。创建列表后，它们将显示在此页面上。',
        createSuccessfully: '创建成功',
        removeSuccessfully: '移除成功',
        createNewList: '创建新列表',
        groupTeam: '团队',
        groupPersonal: '个人',
        edit: '编辑',
        areYouSureYouWantToDeleteTheList: '你确定要删除这个列表吗?',
        proceedingWithDeletion: '继续删除将导致永久删除该列表。',
        byCanceling: '若您取消，不会有任何列表被删除', 
        byCancelingYouEnsureThatNo: '通过取消，您可以确保没有',
        willBeRemoved: '会被移除',
        groupPeople: '人员',
        groupCompany: '公司',
        myList: '我的列表',
        teamList: "团队列表",
        resetAssured: "请放心，您可以根据需要灵活地在不同类型之间切换。",
        wontBeChanged: "通过取消，您可以确保此列表类型不会更改",
        priority: '优先级',
        proceedingWithDeletionWillResultInPeopleDelete: '继续删除将导致永久删除人员',
        proceedingWithDeletionWillResultInCompanyDelete: '继续删除将导致永久删除公司',
        areYouSureYouWantToRemoveCompanyFromList: '您确定要将已选中公司从下列列表中删除吗?',
        areYouSureYouWantToRemovePeopleFromList: '您确定要将已选中人员从下列列表中删除吗?',
        sorryTheListIsCurrrntlyOpen: '抱歉，列表当前处于打开状态。你确定要删除它吗？',
// market-intent
        comingSoon: '即将开放',
        weAreCurrentlyWorkingOnThisFeature: '我们目前正在开发此功能，并将很快推出',
        stayConnectedWithUs: '通过我们的社交媒体渠道与我们保持联系以接收更新',
        selectAWebsite: '请选择一个网站',


}