import request from "@/utils/request";


/*---------------人员saved分组---------------*/
export function getPeopleGroup(data){
    return request({
        url:'/saved/people_group',
        method:'post',
        data
    })
}
/*---------------人员综合search---------------*/
export function searchPeopleList(data){
    return request({
        url:'/peoples/search',
        method:'post',
        data
    })
}
/*---------------人员管理级别---------------*/
export function getManagementLevels(){
    return request({
        url:'/management_levels',
        method:'get',
    })
}
/*---------------人员部门---------------*/
export function getDepartments(){
    return request({
        url:'/departments/title',
        method:'get',
    })
}
/*---------------部门Function---------------*/
export function getDepartmentFunction(){
    return request({
        url:'/departments/function',
        method:'get',
    })
}
/*---------------人员name---------------*/
export function getNames(data){
    return request({
        url:'/peoples/names',//还没有API
        method:'post',
        data
    })
}

/*---------------进入人员个人详情页---------------*/
export function getPeopleDetail(data){
    return request({
        url:'/peoples/lookup',
        method:'post',
        data
    })
}
/*---------------人员表格请求Email地址---------------*/
export function getEmail(data){
    return request({
        url:'/redeem/peoples',
        method:'post',
        data
    })
}
/*---------------从group删除Item---------------*/
export function deletePeople(data){
    return request({
        url:'/saved/peoples/delete',
        method:'delete',
        data
    })
}
/*-----根据groups返回domain ID------*/
export function getDomainIdByGroup(data){
    return request({
        method:'post',
        url:'/peoples/select',
        data
    })
}
/*---------------export------------------*/
export function exportCompany(data){
    return request({
        method:'post',
        url:'/peoples/export',
        data
    })
}